import { BREAKPOINTS } from '@design-system-rt/rtk-ui-kit/constants/components';
import styled from 'styled-components';

export const StyledRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    margin-left: -8px;

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_small}px) {
        margin-left: -16px;
    }

    @media screen and (min-width: ${BREAKPOINTS.desktop_middle}px) {
        margin-left: -32px;
    }
`;
