import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import surveyReducer, { ISurveyState } from 'pages/Survey/redux/reducer';
import surveySaga from 'pages/Survey/redux/sagas';
import viewReducer, { IViewState } from 'redux/view/reducer';

const sagaMiddleware = createSagaMiddleware();

export interface IAppState {
    survey: ISurveyState;
    view: IViewState;
}

export const history = createBrowserHistory();

const rootReducer = combineReducers({
    survey: surveyReducer,
    view: viewReducer,
    router: connectRouter(history),
});

function* rootSaga() {
    yield all([surveySaga()]);
}

export default createStore(
    rootReducer,
    compose(
        composeWithDevTools(
            applyMiddleware(routerMiddleware(history), sagaMiddleware)
        )
    )
);

sagaMiddleware.run(rootSaga);
