import React from 'react';

import {
    FaceFrouning,
    FaceFrouningColor,
    FaceGrin,
    FaceGrinColor,
    FaceNeutral,
    FaceNeutralColor,
    FaceRage,
    FaceRageColor,
    FaceSmiling,
    FaceSmilingColor,
    RadioButton,
} from '@design-system-rt/rtk-ui-kit';

import { RadioGroupWrapper } from '../RadioGroupWrapper/RadioGroupWrapper';
import { ISmilesQuestion } from './types';

const SmilesQuestion: React.FC<ISmilesQuestion> = (props) => {
    const { questionId, onChange, value } = props;
    return (
        <div className="content content-radio">
            <RadioGroupWrapper
                onChange={onChange}
                value={value}
                className="content-images"
            >
                <RadioButton name={`question_${questionId}`} value="1">
                    <FaceRage size={40} className="radio-image-uncheck" />
                    <FaceRageColor size={40} className="radio-image-check" />
                </RadioButton>

                <RadioButton name={`question_${questionId}`} value="2">
                    <FaceFrouning size={40} className="radio-image-uncheck" />
                    <FaceFrouningColor
                        size={40}
                        className="radio-image-check"
                    />
                </RadioButton>

                <RadioButton name={`question_${questionId}`} value="3">
                    <FaceNeutral size={40} className="radio-image-uncheck" />
                    <FaceNeutralColor size={40} className="radio-image-check" />
                </RadioButton>

                <RadioButton name={`question_${questionId}`} value="4">
                    <FaceGrin size={40} className="radio-image-uncheck" />
                    <FaceGrinColor size={40} className="radio-image-check" />
                </RadioButton>

                <RadioButton name={`question_${questionId}`} value="5">
                    <FaceSmiling size={40} className="radio-image-uncheck" />
                    <FaceSmilingColor size={40} className="radio-image-check" />
                </RadioButton>
            </RadioGroupWrapper>
        </div>
    );
};

SmilesQuestion.displayName = 'SmilesQuestion';

export default SmilesQuestion;
