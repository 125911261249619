import React from 'react';

import { RadioButton } from '@design-system-rt/rtk-ui-kit';

import Image from '../../../../components/Image/Image';
import { ImageGroup, ImageGroupWrapper } from './styles';
import { IImageGroupQuestion } from './types';

const ImageGroupQuestion: React.FC<IImageGroupQuestion> = (props) => {
    const { questionId, images, value, onChange } = props;

    return (
        <div className="content content-radio">
            <ImageGroupWrapper count={images.length}>
                <ImageGroup
                    onChange={onChange}
                    value={value}
                    count={images.length}
                >
                    {images.map(({ url }, index) => (
                        <RadioButton
                            key={`${index + 1}`}
                            name={`question_${questionId}`}
                            value={url}
                        >
                            <Image
                                src={url}
                                alt={`variant ${index + 1}`}
                                className="radio-image"
                            />
                        </RadioButton>
                    ))}
                </ImageGroup>
            </ImageGroupWrapper>
        </div>
    );
};

ImageGroupQuestion.displayName = 'ImageGroupQuestion';

export default ImageGroupQuestion;
