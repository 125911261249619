import { THEMES } from '@design-system-rt/rtk-ui-kit/constants/components';

import { InferValueTypes } from 'utils/utils';

import actions from './actions';
import { ActionTypes } from './types';

export interface IViewState {
    theme: THEMES;
}

const initState: IViewState = {
    theme: THEMES.light,
};

type ViewActions = ReturnType<InferValueTypes<typeof actions>>;

const SurveyStore = (state = initState, action: ViewActions): IViewState => {
    switch (action.type) {
        case ActionTypes.ACT_SET_VIEW_THEME:
            return {
                ...state,
                theme: action.payload,
            };
        default:
            return state;
    }
};

export default SurveyStore;
