import React, { useEffect, useState } from 'react';

import { AnswerItemType, IAnswerItem } from '../../redux/types';
import CheckboxGroupQuestion from '../CheckboxGroupQuestion/CheckboxGroupQuestion';
import TextQuestion from '../TextQuestion';
import { ICustomCheckboxGroupQuestion } from './types';

const otherValue = 'Другое';

const CustomCheckboxGroupQuestion: React.FC<ICustomCheckboxGroupQuestion> = (
    props
) => {
    const { question, values, onChange } = props;
    let checkboxValues = question.values ?? [];

    if (question.hasOther && checkboxValues.indexOf(otherValue) < 0) {
        checkboxValues = [...checkboxValues, otherValue];
    }

    const searchCheckboxValues = (newValues?: Array<IAnswerItem>) => {
        const checkeds =
            newValues
                ?.filter((newValue) => newValue.type === AnswerItemType.GENERAL)
                .map((newValue) => newValue.value) ?? [];
        const otherValues = newValues?.filter(
            (newValue) => newValue.type === AnswerItemType.OTHER
        );
        if (otherValues && otherValues.length > 0) {
            return [...checkeds, otherValue];
        }

        return checkeds;
    };

    const [defaultChecked, setDefaultChecked] = useState(
        searchCheckboxValues(values)
    );

    useEffect(() => {
        setDefaultChecked(searchCheckboxValues(values));
    }, [question, values]);

    const otherValueItem = values?.filter(
        (newValue) => newValue.type === AnswerItemType.OTHER
    )[0];

    return (
        <>
            <CheckboxGroupQuestion
                values={checkboxValues}
                defaultChecked={defaultChecked}
                onChange={(newValues) => {
                    setDefaultChecked(newValues);

                    if (newValues.find((value) => value === otherValue)) {
                        onChange([
                            ...newValues
                                .filter((value) => value !== otherValue)
                                .map((value) => ({
                                    value,
                                    type: AnswerItemType.GENERAL,
                                })),
                            otherValueItem ?? {
                                value: '',
                                type: AnswerItemType.OTHER,
                            },
                        ]);
                    } else {
                        onChange(
                            newValues.map((value) => ({
                                value,
                                type: AnswerItemType.GENERAL,
                            }))
                        );
                    }
                }}
            />
            {(otherValueItem || defaultChecked.includes('Другое')) && (
                <TextQuestion
                    title="Свой ответ"
                    value={otherValueItem?.value ?? ''}
                    onChange={(newValue) => {
                        return onChange([
                            ...(values?.filter(
                                (newValue) =>
                                    newValue.type === AnswerItemType.GENERAL
                            ) ?? []),
                            { value: newValue, type: AnswerItemType.OTHER },
                        ]);
                    }}
                />
            )}
        </>
    );
};

CustomCheckboxGroupQuestion.displayName = 'CustomCheckboxGroupQuestion';

export default CustomCheckboxGroupQuestion;
