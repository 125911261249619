import React from 'react';

import {
    AttentionColor,
    ErrorColor,
    InformationColor,
    OkColor,
    Typography,
} from '@design-system-rt/rtk-ui-kit';
import { BREAKPOINTS } from '@design-system-rt/rtk-ui-kit/constants/components';
import { useThemeContext } from '@design-system-rt/rtk-ui-kit/hooks/useThemeContext';
import styled from 'styled-components';

import Layout from 'components/Layout';
import { StyledBox } from 'components/Question/styles';
import Col from 'core/components/Grid/Col';
import Container from 'core/components/Grid/Container';
import Row from 'core/components/Grid/Row';

import { IPageEventProps, PageEventType } from './types';

const IconWrapper = styled.div<{ bgColor: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_small}px) {
        margin-bottom: 20px;
    }
`;

const PageEvent: React.FC<IPageEventProps> = ({
    children,
    type = PageEventType.INFO,
}: IPageEventProps) => {
    const { themeName, themeConfig } = useThemeContext();

    let icon;
    let wrapperIconColor;

    switch (type) {
        case PageEventType.SUCCESS:
            icon = <OkColor size={40} />;
            wrapperIconColor = themeConfig.systemColors.success;
            break;
        case PageEventType.WARNING:
            icon = <AttentionColor size={40} />;
            wrapperIconColor = themeConfig.systemColors.warning;
            break;
        case PageEventType.ERROR:
            icon = <ErrorColor size={40} />;
            wrapperIconColor = themeConfig.systemColors.error;
            break;
        case PageEventType.INFO:
        default:
            icon = <InformationColor size={40} />;
            wrapperIconColor = themeConfig.systemColors.info;
            break;
    }

    return (
        <Layout>
            <Container noGapMobileS>
                <Row>
                    <Col mobileS={12}>
                        <StyledBox
                            spacing="xl"
                            spacingTop="xxxl"
                            spacingBottom="xxxl"
                            themeName={themeName}
                        >
                            <div className="thanks">
                                <IconWrapper bgColor={wrapperIconColor(0.2)}>
                                    {icon}
                                </IconWrapper>
                                {/* <Typography tag="h1" variant="h1"> */}
                                {children}
                                {/* </Typography> */}
                            </div>
                        </StyledBox>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default PageEvent;
