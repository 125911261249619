import React from 'react';

import { THEMES } from '@design-system-rt/rtk-ui-kit/constants/components';
import { useThemeContext } from '@design-system-rt/rtk-ui-kit/hooks/useThemeContext';

import { IIcon } from './types';

const Logo: React.FC<IIcon> = (props) => {
    const { width = 193, height = 50, className } = props;
    const { themeName, themeConfig } = useThemeContext();
    const whiteTBlackColor =
        themeName === THEMES.light
            ? themeConfig.greyScale.white
            : themeConfig.greyScale.black;
    const blackToWhiteColor =
        themeName === THEMES.light
            ? themeConfig.greyScale.black
            : themeConfig.greyScale.white;

    return (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox="0 0 193 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="38"
                width="23"
                height="12"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.0001 50H3.49261C2.94861 50 2.41951 49.822 1.98601 49.493C0.888713 48.661 0.673713 47.097 1.50581 46L9.59431 38C9.59451 38 13.7298 42 22.0001 50Z"
                    fill={whiteTBlackColor}
                />
            </mask>
            <g mask="url(#mask0)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M-4.00098 33H27.0001V55H-4.00098V33Z"
                    fill={themeConfig.baseColors.primary2()}
                />
            </g>
            <mask
                id="mask1"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="30"
                height="50"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 46V15.316C0 13.509 0.6988 11.772 1.9503 10.468L12 0L30 18L2.251 45.766C1.7843 46.231 1.5219 46.863 1.5219 47.522C1.5219 48.891 2.6314 50 4 50C1.7909 50 0 48.209 0 46Z"
                    fill={whiteTBlackColor}
                />
            </mask>
            <g mask="url(#mask1)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M-5 -5H35V55H-5V-5Z"
                    fill={themeConfig.baseColors.primary1()}
                />
            </g>
            <mask
                id="mask2"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="145"
                y="31"
                width="14"
                height="15"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M158.273 31.1741L151.649 37.8551L158.447 45.5831H154.032L148.774 39.6281V45.5831H145.113V31.1741H148.774V36.4031L153.944 31.1741H158.273Z"
                    fill={whiteTBlackColor}
                />
            </mask>
            <g mask="url(#mask2)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M140.113 26.1741H163.447V50.5831H140.113V26.1741Z"
                    fill={blackToWhiteColor}
                />
            </g>
            <mask
                id="mask3"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="33"
                y="25"
                width="17"
                height="21"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33 45.583V25.248H42.2376C46.8275 25.248 49.8487 27.601 49.8487 31.871C49.8487 36.142 46.8275 38.494 42.2376 38.494H36.8343V45.583H33ZM42.1215 35.067C44.7939 35.067 46.0141 33.788 46.0141 31.871C46.0141 29.954 44.7939 28.676 42.1215 28.676H36.8343V35.067H42.1215Z"
                    fill={whiteTBlackColor}
                />
            </mask>
            <g mask="url(#mask3)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28 20.248H54.8487V50.583H28V20.248Z"
                    fill={blackToWhiteColor}
                />
            </g>
            <mask
                id="mask4"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="50"
                y="30"
                width="17"
                height="16"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M58.5038 42.5611C60.6535 42.5611 62.6579 41.0221 62.6579 38.3781C62.6579 35.7351 60.6535 34.1951 58.5038 34.1951C56.3544 34.1951 54.3498 35.7351 54.3498 38.3781C54.3498 41.0221 56.3544 42.5611 58.5038 42.5611ZM58.5038 30.7671C62.4547 30.7671 66.3763 33.3241 66.3763 38.3781C66.3763 43.4331 62.4547 45.9891 58.5038 45.9891C54.5532 45.9891 50.6313 43.4331 50.6313 38.3781C50.6313 33.3241 54.5532 30.7671 58.5038 30.7671Z"
                    fill={whiteTBlackColor}
                />
            </mask>
            <g mask="url(#mask4)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M45.6313 25.7671H71.3763V50.9891H45.6313V25.7671Z"
                    fill={blackToWhiteColor}
                />
            </g>
            <mask
                id="mask5"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="68"
                y="30"
                width="15"
                height="16"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M75.7212 42.5611C77.5222 42.5611 78.4519 41.6901 79.3233 40.4701L82.3445 42.1261C80.8918 44.5951 78.8295 45.9891 75.7503 45.9891C71.4801 45.9891 68.1682 42.9681 68.1682 38.3781C68.1682 33.7881 71.6543 30.7671 75.7503 30.7671C78.8875 30.7671 81.1536 32.1321 82.1991 34.3691L79.0619 36.1711C78.1614 34.8051 77.0285 34.1951 75.7212 34.1951C73.5715 34.1951 71.8867 35.8221 71.8867 38.3781C71.8867 40.9641 73.5135 42.5611 75.7212 42.5611Z"
                    fill={whiteTBlackColor}
                />
            </mask>
            <g mask="url(#mask5)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M63.1682 25.7671H87.3445V50.9891H63.1682V25.7671Z"
                    fill={blackToWhiteColor}
                />
            </g>
            <mask
                id="mask6"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="112"
                y="31"
                width="15"
                height="15"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M114.693 39.3371L115.303 31.1741H126.226V45.5541H122.566V34.4571H118.441L118.092 39.4821C117.802 43.7521 116.64 45.7571 113.532 45.7571C112.689 45.7571 112.234 45.6181 112.234 45.6181L112.216 41.8891C112.569 41.9821 112.812 42.0391 113.328 42.0391C114.083 42.0391 114.519 41.5451 114.693 39.3371Z"
                    fill={whiteTBlackColor}
                />
            </mask>
            <g mask="url(#mask6)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M107.216 26.1741H131.226V50.7571H107.216V26.1741Z"
                    fill={blackToWhiteColor}
                />
            </g>
            <mask
                id="mask7"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="96"
                y="30"
                width="16"
                height="16"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M100.302 36.4611H107.943C107.594 34.6601 106.229 33.5561 104.195 33.5561C102.278 33.5561 100.825 34.8341 100.302 36.4611ZM104.311 30.7671C108.436 30.7671 111.341 33.7601 111.341 37.9721V39.2501H100.244C100.477 41.4581 102.046 42.8521 104.195 42.8521C106.141 42.8521 106.955 42.0101 107.855 40.8771L110.76 42.3291C109.308 44.7981 107.304 45.9891 104.137 45.9891C100.128 45.9891 96.7295 42.9391 96.7295 38.3781C96.7295 33.8181 100.128 30.7671 104.311 30.7671Z"
                    fill={whiteTBlackColor}
                />
            </mask>
            <g mask="url(#mask7)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M91.7295 25.7671H116.341V50.9891H91.7295V25.7671Z"
                    fill={blackToWhiteColor}
                />
            </g>
            <mask
                id="mask8"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="128"
                y="30"
                width="15"
                height="16"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M131.848 36.4611H139.488C139.139 34.6601 137.774 33.5561 135.741 33.5561C133.823 33.5561 132.371 34.8341 131.848 36.4611ZM135.857 30.7671C139.982 30.7671 142.887 33.7601 142.887 37.9721V39.2501H131.79C132.022 41.4581 133.591 42.8521 135.741 42.8521C137.687 42.8521 138.5 42.0101 139.401 40.8771L142.306 42.3291C140.853 44.7981 138.849 45.9891 135.683 45.9891C131.674 45.9891 128.275 42.9391 128.275 38.3781C128.275 33.8181 131.674 30.7671 135.857 30.7671Z"
                    fill={whiteTBlackColor}
                />
            </mask>
            <g mask="url(#mask8)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M123.275 25.7671H147.887V50.9891H123.275V25.7671Z"
                    fill={blackToWhiteColor}
                />
            </g>
            <mask
                id="mask9"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="83"
                y="31"
                width="13"
                height="15"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M83.4832 34.3691V31.1741H95.7421V34.3691H91.4425V45.5831H87.7826V34.3691H83.4832Z"
                    fill={whiteTBlackColor}
                />
            </mask>
            <g mask="url(#mask9)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M78.4832 26.1741H100.742V50.5831H78.4832V26.1741Z"
                    fill={blackToWhiteColor}
                />
            </g>
            <mask
                id="mask10"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="157"
                y="30"
                width="17"
                height="16"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M165.714 42.5621C167.864 42.5621 169.868 41.0221 169.868 38.3781C169.868 35.7351 167.864 34.1951 165.714 34.1951C163.564 34.1951 161.56 35.7351 161.56 38.3781C161.56 41.0221 163.564 42.5621 165.714 42.5621ZM165.714 30.7671C169.664 30.7671 173.586 33.3241 173.586 38.3781C173.586 43.4331 169.664 45.9891 165.714 45.9891C161.763 45.9891 157.841 43.4331 157.841 38.3781C157.841 33.3241 161.763 30.7671 165.714 30.7671Z"
                    fill={whiteTBlackColor}
                />
            </mask>
            <g mask="url(#mask10)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M152.841 25.7671H178.586V50.9891H152.841V25.7671Z"
                    fill={blackToWhiteColor}
                />
            </g>
            <mask
                id="mask11"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="175"
                y="31"
                width="18"
                height="15"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M188.15 31.1709H192.935V45.5539H189.455V36.4489L185.599 45.5539H182.757L178.9 36.4489V45.5539H175.421V31.1709H180.205L184.178 41.2619L188.15 31.1709Z"
                    fill={whiteTBlackColor}
                />
            </mask>
            <g mask="url(#mask11)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M170.421 26.1709H197.935V50.5539H170.421V26.1709Z"
                    fill={blackToWhiteColor}
                />
            </g>
        </svg>
    );
};

Logo.displayName = 'Logo';

export default Logo;
