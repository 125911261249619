import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Layout from 'components/Layout';
import PageEvent from 'pages/Event/page';
import { ISurveyProps } from 'pages/Survey/types';

import { fetchSurveyRequest } from '../../redux/actions';
import {
    isSurveyFetchingSelector,
    surveyErrorSelector,
} from '../../redux/selectors';
import SurveyPage from '../SurveyPage';

const Survey: React.FC<ISurveyProps> = ({ id, mode, access }) => {
    const dispatch = useDispatch();
    const isFetching = useSelector(isSurveyFetchingSelector);
    const error = useSelector(surveyErrorSelector);

    useEffect(() => {
        dispatch(fetchSurveyRequest({ id, mode, access }));
    }, [dispatch, id, mode, access]);

    if (error) {
        return <PageEvent>{error}</PageEvent>;
    }

    if (isFetching) {
        return null;
    }

    return (
        <Layout>
            <SurveyPage />
        </Layout>
    );
};

export default Survey;
