import { BREAKPOINTS } from '@design-system-rt/rtk-ui-kit/constants/components';
import styled, { css } from 'styled-components';

import { ICol } from './types';

const generateWidth = (size?: number) => {
    if (size) {
        const width = (100 / 12) * size;

        return css`
            flex: 0 0 ${width}%;
            max-width: ${width}%;
        `;
    }

    return '';
};

const generateWidthMobileS = ({ mobileS }: ICol) => generateWidth(mobileS);
const generateWidthTabletS = ({ tabletS }: ICol) => generateWidth(tabletS);
const generateWidthTabletM = ({ tabletM }: ICol) => generateWidth(tabletM);
const generateWidthTabletL = ({ tabletL }: ICol) => generateWidth(tabletL);
const generateWidthDesktopS = ({ desktopS }: ICol) => generateWidth(desktopS);
const generateWidthDesktopM = ({ desktopM }: ICol) => generateWidth(desktopM);

export const StyledColSpacing = styled.div`
    ${generateWidthMobileS};
    min-height: 1px;
    padding-left: 8px;

    .header-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px 20px;

        .mobile-back {
            margin: 20px 20px 0px 0px;
            padding: 0px;
            border: 0px;
        }

        .page-header {
            margin: 22px 0px 0px;
            display: flex;
            flex-direction: column;
        }
    }

    .survey-questions {
        margin: 12px 0px;

        @media screen and (max-width: ${BREAKPOINTS.tablet_vertical_small}px) {
            margin: 0;
        }
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_small}px) {
        ${generateWidthTabletS};
        padding-left: 16px;

        .header-container {
            display: block;

            .mobile-back {
                display: none;
            }

            .page-header {
                max-width: 816px;
                display: flex;
                flex-direction: column;
                padding: 12px 0px;
                margin: 32px auto -8px;
            }
        }

        .survey-questions {
            margin-bottom: 56px;
        }
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_large}px) {
        ${generateWidthTabletM};
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_horizontal}px) {
        ${generateWidthTabletL};
    }

    @media screen and (min-width: ${BREAKPOINTS.desktop_small}px) {
        ${generateWidthDesktopS};
    }

    @media screen and (min-width: ${BREAKPOINTS.desktop_middle}px) {
        ${generateWidthDesktopM};
        padding-left: 32px;
    }
`;

export const StyledCol = styled.div<ICol>`
    width: 100%;
    box-sizing: border-box;
`;
