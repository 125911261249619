import React from 'react';

import SurveyContainer from './components/SurveyContainer';
import { ISurveyProps } from './types';

const Survey: React.FC<ISurveyProps> = (props) => (
    <SurveyContainer {...props} />
);

export default Survey;
