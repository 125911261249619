import React from 'react';

import { StyledCol, StyledColSpacing } from './styles';
import { ICol } from './types';

const Col: React.FC<ICol> = (props) => {
    const {
        id,
        className,
        style,
        mobileS,
        tabletS,
        tabletM,
        tabletL,
        desktopS,
        desktopM,
        children,
    } = props;

    return (
        <StyledColSpacing
            mobileS={mobileS}
            tabletS={tabletS}
            tabletM={tabletM}
            tabletL={tabletL}
            desktopS={desktopS}
            desktopM={desktopM}
        >
            <StyledCol id={id} className={className} style={style}>
                {children}
            </StyledCol>
        </StyledColSpacing>
    );
};

Col.displayName = 'Col';

export default Col;
