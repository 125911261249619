import React from 'react';

import Textarea from '@design-system-rt/rtk-ui-kit/components/TextArea/TextArea';

import { ITextQuestion } from './types';

const TextQuestion: React.FC<ITextQuestion> = (props) => {
    const { title, onChange, value } = props;
    return (
        <Textarea
            label={title || 'Ответ'}
            shape="rounded"
            className="content content-comment"
            onChange={(e) => {
                onChange((e.target as any).value ?? '');
            }}
            value={value}
            color="primary1"
        />
    );
};

export default TextQuestion;
