import { action } from 'typesafe-actions';

import {
    IServerSurvey,
    IServerSurveyThankPage,
} from 'repositories/survey/types';

import { ActionTypes, IAnswer, IAnswerItem, ISurveyConfig } from './types';

export const fetchSurveyRequest = (surveyConfig: ISurveyConfig) => {
    return action(ActionTypes.ACT_FETCH_SURVEY_REQUEST, surveyConfig);
};

export const fetchSurveySuccess = (data: IServerSurvey) => {
    return action(ActionTypes.ACT_FETCH_SURVEY_SUCCESS, data);
};

export const fetchSurveyError = (error: string) => {
    return action(ActionTypes.ACT_FETCH_SURVEY_ERROR, error);
};

export const setSurveyAnswers = (answers: Array<IAnswer>) => {
    return action(ActionTypes.ACT_SET_SURVEY_ANSWERS, answers);
};

export const setQuizEnd = () => {
    return action(ActionTypes.ACT_QUIZ_END);
};

export const setSurveyAnswer = (data: {
    questionId: string;
    answers?: Array<IAnswerItem>;
    sequenceId?: string;
}) => {
    return action(ActionTypes.ACT_SET_SURVEY_ANSWER, data);
};

export const removePageAnswers = (data: { questionIds: string[] }) => {
    return action(ActionTypes.ACT_REMOVE_PAGE_ANSWERS, data);
};

export const fetchAnswersRequest = (answers?: Array<IAnswer>) => {
    return action(ActionTypes.ACT_FETCH_ANSWERS_REQUEST, answers);
};

export const fetchAnswersSuccess = (thankYou: IServerSurveyThankPage) => {
    return action(ActionTypes.ACT_FETCH_ANSWERS_SUCCESS, thankYou);
};

export const fetchAnswersError = (error: string) => {
    return action(ActionTypes.ACT_FETCH_ANSWERS_ERROR, error);
};

export const setSurveyCurrentPage = (currentPage?: string) => {
    return action(ActionTypes.ACT_SET_SURVEY_CURRENT_PAGE, currentPage);
};

export const setSurveyPreviousPage = (previousPage?: string) => {
    return action(ActionTypes.ACT_SET_SURVEY_PREVIOUS_PAGE, previousPage);
};

export const setSurveyNextPage = (nextPage?: string) => {
    return action(ActionTypes.ACT_SET_SURVEY_NEXT_PAGE, nextPage);
};

export const setSurveyNavigationStack = (previousPages?: string[]) => {
    return action(ActionTypes.ACT_SET_SURVEY_NAVIGATION_STACK, previousPages);
};

export const removeHiddenAnswers = () => {
    return action(ActionTypes.ACT_REMOVE_HIDDEN_ANSWERS);
};

export default {
    fetchSurveyRequest,
    fetchSurveySuccess,
    fetchSurveyError,
    setSurveyAnswers,
    removePageAnswers,
    removeHiddenAnswers,
    setQuizEnd,
    setSurveyAnswer,
    fetchAnswersRequest,
    fetchAnswersSuccess,
    fetchAnswersError,
    setSurveyCurrentPage,
    setSurveyPreviousPage,
    setSurveyNextPage,
    setSurveyNavigationStack,
};
