import {
    IServerSurvey,
    IServerSurveyThankPage,
} from 'repositories/survey/types';
import { InferValueTypes } from 'utils/utils';

import actions from './actions';
import { ActionTypes, IAnswer, ISurveyConfig } from './types';

const START_URL =
    'https://start.rt.ru/?utm_source=survey&utm_medium=start&utm_campaign=int_test&utm_content=opros_new&utm_term=all';

const DEFAULT_THANK_YOU_PAGE = {
    id: 0,
    title: 'Спасибо за прохождение опроса!',
    description: '',
    linkEnabled: true,
    linkText: 'Перейти',
    linkUrl: START_URL,
};
export interface ISurveyState {
    config?: ISurveyConfig;
    isFetching: boolean;
    showThankyou: boolean;
    error?: string;
    data?: IServerSurvey;
    answers?: Array<IAnswer>;
    answersIsFetching: boolean;
    answersError?: string;
    currentPage?: string;
    navigationStack?: string[];
    thankYouPage: IServerSurveyThankPage;
}

const initState: ISurveyState = {
    isFetching: false,
    showThankyou: false,
    answersIsFetching: false,
    thankYouPage: DEFAULT_THANK_YOU_PAGE,
};

type PageSurveyActions = ReturnType<InferValueTypes<typeof actions>>;

const SurveyStore = (
    state = initState,
    action: PageSurveyActions
): ISurveyState => {
    switch (action.type) {
        case ActionTypes.ACT_FETCH_SURVEY_REQUEST:
            return {
                ...state,
                isFetching: true,
                config: action.payload,
                error: undefined,
            };
        case ActionTypes.ACT_FETCH_SURVEY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                answers: undefined,
                currentPage: undefined,
            };
        case ActionTypes.ACT_FETCH_SURVEY_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.payload,
                data: undefined,
                answers: undefined,
                currentPage: undefined,
            };
        case ActionTypes.ACT_SET_SURVEY_ANSWERS:
            return {
                ...state,
                answers: action.payload.filter((ans) => ans.answers.length > 0),
            };
        case ActionTypes.ACT_FETCH_ANSWERS_REQUEST:
            return {
                ...state,
                answersIsFetching: true,
                answersError: undefined,
            };
        case ActionTypes.ACT_FETCH_ANSWERS_SUCCESS:
            return {
                ...state,
                answersIsFetching: false,
                thankYouPage: action.payload,
            };
        case ActionTypes.ACT_FETCH_ANSWERS_ERROR:
            return {
                ...state,
                answersIsFetching: false,
                answersError: action.payload,
            };
        case ActionTypes.ACT_QUIZ_END:
            return {
                ...state,
                showThankyou: true,
            };
        case ActionTypes.ACT_SET_SURVEY_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload,
            };
        case ActionTypes.ACT_SET_SURVEY_NAVIGATION_STACK:
            return {
                ...state,
                navigationStack: action.payload,
            };
        case ActionTypes.ACT_REMOVE_PAGE_ANSWERS:
            return {
                ...state,
                answers: state.answers?.filter(
                    (answer) =>
                        !action.payload.questionIds.includes(answer.questionId)
                ),
            };
        default:
            return state;
    }
};

export default SurveyStore;
