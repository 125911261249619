import { Typography } from '@design-system-rt/rtk-ui-kit';
import {
    BREAKPOINTS,
    THEMES,
} from '@design-system-rt/rtk-ui-kit/constants/components';
import { ThemeNamesType } from '@design-system-rt/rtk-ui-kit/types/theme';
import styled from 'styled-components';

import Logo from '../../components/icons/Logo';
import MobileLogo from '../../components/icons/MobileLogo';
import Col from '../../core/components/Grid/Col';

export const StyledLayout = styled.div<{ themeName: ThemeNamesType }>`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background-color: ${({ theme, themeName }) =>
        themeName === THEMES.dark ? theme.greyScale[95] : theme.greyScale[5]};
`;

export const Column = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const FooterColRight = styled(Col)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_small}px) {
        margin-top: 14px;
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_horizontal}px) {
        flex-direction: column;
        align-items: flex-end;
    }
`;

export const FooterPhone = styled(Typography)`
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    a {
        display: inline-block;
        margin-top: 4px;
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_small}px) {
        flex-direction: row;
        align-items: center;

        a {
            margin-top: 0;
        }
    }
`;

export const FooterPolicy = styled(Typography)`
    margin-top: 12px;

    a {
        text-decoration: underline;
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_small}px) {
        margin-top: 16px;
    }
`;

export const FooterCopyright = styled(Typography)`
    display: flex;
    align-items: center;

    span {
        margin-right: 8px;
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_horizontal}px) {
        margin-top: 16px;
    }
`;

export const StyledLogo = styled(Logo)`
    display: none;

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_small}px) {
        display: block;
    }
`;

export const StyledMobileLogo = styled(MobileLogo)`
    display: none;

    @media screen and (max-width: ${BREAKPOINTS.tablet_vertical_small}px) {
        display: block;
    }
`;
