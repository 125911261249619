import { CheckboxGroup } from '@design-system-rt/rtk-ui-kit';
import styled from 'styled-components';

export const StyledCheckBoxGroup = styled(CheckboxGroup)`
    padding-left: 0;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    word-break: break-all;
`;
