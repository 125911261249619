import React from 'react';

import { useThemeContext } from '@design-system-rt/rtk-ui-kit/hooks/useThemeContext';

import { StyledHeader } from './styles';
import { IHeader } from './types';

const Header: React.FC<IHeader> = (props) => {
    const { id, className, children } = props;
    const { themeName } = useThemeContext();

    return (
        <StyledHeader id={id} className={className} themeName={themeName}>
            {children}
        </StyledHeader>
    );
};

Header.displayName = 'Header';

export default Header;
