import '@design-system-rt/rtk-fonts';
import React from 'react';

import { ThemeProvider } from '@design-system-rt/rtk-ui-kit';
import { ConnectedRouter } from 'connected-react-router';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import Survey from 'pages/Survey';
import { SurveyAccess, SurveyMode } from 'pages/Survey/redux/types';
import { viewThemeSelector } from 'redux/view/selectors';

import PageFinish from './pages/Finish/page';
import { history } from './store';

function App() {
    const theme = useSelector(viewThemeSelector);

    return (
        <ThemeProvider themeName={theme}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route
                        path="/survey/finish"
                        exact
                        render={() => <PageFinish />}
                    />
                    <Route
                        path="/survey/preview/:id"
                        exact
                        render={({ match }) => (
                            <Survey
                                id={match.params.id}
                                mode={SurveyMode.META}
                                access={SurveyAccess.VIEW}
                            />
                        )}
                    />
                    <Route
                        path="/survey/:token"
                        exact
                        render={({ match }) => (
                            <Survey
                                id={match.params.token}
                                mode={SurveyMode.ITEM}
                                access={SurveyAccess.ANSWER}
                            />
                        )}
                    />
                </Switch>
            </ConnectedRouter>
        </ThemeProvider>
    );
}

export default App;
