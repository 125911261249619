import { Helpers, HashTable } from './helpers';

export class ProcessValue {
    public values: HashTable<any> = null as any;
    public properties: HashTable<any> = null as any;
    constructor() {}
    public getFirstName(text: string, obj: any = null): string {
        if (!text) return text;
        let res = '';
        if (obj) {
            res = this.getFirstPropertyName(text, obj);
            if (res) return res;
        }
        for (let i = 0; i < text.length; i++) {
            const ch = text[i];
            if (ch == '.' || ch == '[') break;
            res += ch;
        }
        return res;
    }
    public hasValue(
        text: string,
        values: HashTable<any> = null as any
    ): boolean {
        if (!values) values = this.values;
        const res = this.getValueCore(text, values);
        return res.hasValue;
    }
    public getValue(text: string, values: HashTable<any> = null as any): any {
        if (!values) values = this.values;
        const res = this.getValueCore(text, values);
        return res.value;
    }
    public setValue(obj: any, text: string, value: any) {
        if (!text) return;
        const nonNestedObj = this.getNonNestedObject(obj, text, true);
        if (!nonNestedObj) return;
        obj = nonNestedObj.value;
        text = nonNestedObj.text;
        if (!!obj && !!text) {
            obj[text] = value;
        }
    }
    public getValueInfo(valueInfo: any) {
        if (valueInfo.path) {
            valueInfo.value = this.getValueFromPath(
                valueInfo.path,
                this.values
            );
            valueInfo.hasValue =
                valueInfo.value !== null &&
                !Helpers.isValueEmpty(valueInfo.value);
            if (
                !valueInfo.hasValue &&
                valueInfo.path.length > 1 &&
                valueInfo.path[valueInfo.path.length - 1] == 'length'
            ) {
                valueInfo.hasValue = true;
                valueInfo.value = 0;
            }
            return;
        }
        const res = this.getValueCore(valueInfo.name, this.values);
        valueInfo.value = res.value;
        valueInfo.hasValue = res.hasValue;
        valueInfo.path = res.hasValue ? res.path : null;
    }
    private getValueFromPath(path: Array<string | number>, values: any): any {
        let index = 0;
        while (!!values && index < path.length) {
            const ind_name = path[index];
            if (
                Helpers.isNumber(ind_name) &&
                Array.isArray(values) &&
                ind_name >= values.length
            )
                return null;
            values = values[ind_name];
            index++;
        }
        return values;
    }
    private getValueCore(text: string, values: any): any {
        const res: any = { hasValue: false, value: null, path: null };
        const curValue = values;
        if (!curValue && curValue !== 0 && curValue !== false) return res;
        if (
            text &&
            text.lastIndexOf('.length') > -1 &&
            text.lastIndexOf('.length') === text.length - '.length'.length
        ) {
            res.value = 0;
            res.hasValue = true;
        }
        const nonNestedObj = this.getNonNestedObject(curValue, text, false);
        if (!nonNestedObj) return res;
        res.path = nonNestedObj.path;
        res.value = nonNestedObj.text
            ? this.getObjectValue(nonNestedObj.value, nonNestedObj.text)
            : nonNestedObj.value;
        res.hasValue = !Helpers.isValueEmpty(res.value);
        return res;
    }
    private getNonNestedObject(
        obj: any,
        text: string,
        createPath: boolean
    ): any {
        let curName = this.getFirstPropertyName(text, obj, createPath);
        const path = curName ? [curName] : null;
        while (text != curName && !!obj) {
            const isArray = text[0] == '[';
            if (!isArray) {
                if (!curName && text == this.getFirstName(text))
                    return { value: obj, text: text, path: path };
                obj = this.getObjectValue(obj, curName);
                if (Helpers.isValueEmpty(obj) && !createPath) return null;
                text = text.substr(curName.length);
            } else {
                const objInArray = this.getObjInArray(obj, text);
                if (!objInArray) return null;
                obj = objInArray.value;
                text = objInArray.text;
                path?.push(objInArray.index);
            }
            if (!!text && text[0] == '.') {
                text = text.substr(1);
            }
            curName = this.getFirstPropertyName(text, obj, createPath);
            if (curName) {
                path?.push(curName);
            }
        }
        return { value: obj, text: text, path: path };
    }
    private getObjInArray(curValue: any, text: string): any {
        if (!Array.isArray(curValue)) return null;
        let index = 1;
        let str = '';
        while (index < text.length && text[index] != ']') {
            str += text[index];
            index++;
        }
        text = index < text.length ? text.substr(index + 1) : '';
        index = this.getIntValue(str);
        if (index < 0 || index >= curValue.length) return null;
        return { value: curValue[index], text: text, index: index };
    }
    private getFirstPropertyName(
        name: string,
        obj: any,
        createProp = false
    ): string {
        if (!name) return name;
        if (!obj) obj = {};
        if (obj.hasOwnProperty(name)) return name;
        const nameInLow = name.toLowerCase();
        const A = nameInLow[0];
        const a = A.toUpperCase();
        for (const key in obj) {
            const first = key[0];
            if (first === a || first === A) {
                const keyName = key.toLowerCase();
                if (keyName == nameInLow) return key;
                if (nameInLow.length <= keyName.length) continue;
                const ch = nameInLow[keyName.length];
                if (ch != '.' && ch != '[') continue;
                if (keyName == nameInLow.substr(0, keyName.length)) return key;
            }
        }
        if (createProp && name[0] !== '[') {
            const ind = name.indexOf('.');
            if (ind > -1) {
                name = name.substr(0, ind);
                obj[name] = {};
            }
            return name;
        }
        return '';
    }
    private getObjectValue(obj: any, name: string): any {
        if (!name) return null;
        return obj[name];
    }
    private getIntValue(str: any) {
        if (str == '0' || ((str | 0) > 0 && str % 1 == 0)) return Number(str);
        return -1;
    }
}
