import {
    IServerSurvey,
    IServerSurveyPage,
    IServerSurveyPageQuestion,
    IServerSurveySettings,
} from 'repositories/survey/types';

const TestSurvey = {
    settings: {
        showPageTitles: true,
        showProgressBar: 'bottom',
        showQuestionNumbers: true,
        businessName: 'Множественный выбор',
    } as IServerSurveySettings,
    pages: [
        {
            pageId: 'page1',
            titleHtml: '',
            title: '',
            titleVisible: false,
            description: '',
            condition: '',
            questions: [
                {
                    questionId: 'q1',
                    type: 'RADIOGROUP',
                    title: 'Как часто вы используете интернет за границей и насколько вам важна услуга роуминга в тарифе?',
                    description:
                        'выберете, пожалуйтса, один варинат ответа или напишите свой варинат',
                    values: ['Всегда', 'Часто', 'Редко', 'Никогда'],
                    hasOther: true,
                    colCount: 1,
                    showLabel: false,
                    condition: '',
                    maxValue: null,
                    isRequired: true,
                } as IServerSurveyPageQuestion,
            ],
        } as IServerSurveyPage,
        {
            pageId: 'page2',
            titleHtml: '',
            title: '',
            titleVisible: false,
            description: '',
            condition: '',
            questions: [
                {
                    questionId: 'q2',
                    type: 'CHECKBOX',
                    title: 'Где вы предпочитаете отдыхать?',
                    description: 'выберете все варинаты',
                    values: [
                        'На даче',
                        'В санатории',
                        'На море',
                        'На море заграницей',
                        'Туризм',
                    ],
                    hasOther: false,
                    colCount: 1,
                    showLabel: false,
                    condition: '',
                    maxValue: null,
                    isRequired: false,
                } as IServerSurveyPageQuestion,
            ],
        } as IServerSurveyPage,
        {
            pageId: 'page3',
            titleHtml: '',
            title: '',
            titleVisible: false,
            description: '',
            condition: '',
            questions: [
                {
                    questionId: 'q3',
                    type: 'STARS',
                    title: 'На сколько вы удовлетворены качеством связи?',
                    values: [],
                    hasOther: false,
                    colCount: 1,
                    showLabel: false,
                    condition: '',
                    maxValue: 5,
                    isRequired: true,
                } as IServerSurveyPageQuestion,
            ],
        } as IServerSurveyPage,
        {
            pageId: 'page4',
            titleHtml: '',
            title: '',
            titleVisible: false,
            description: '',
            condition: '',
            questions: [
                {
                    questionId: 'q4',
                    type: 'RATING',
                    title: 'Готовы ли вы рекомендовать наши услуги друзьям?',
                    values: [],
                    hasOther: false,
                    colCount: 1,
                    showLabel: false,
                    condition: '',
                    maxValue: null,
                    isRequired: true,
                } as IServerSurveyPageQuestion,
            ],
        } as IServerSurveyPage,
    ],
} as IServerSurvey;

const AnotherTestSurvey = {
    settings: {
        showPageTitles: true,
        showProgressBar: 'bottom',
        showQuestionNumbers: true,
        businessName: 'Множественный выбор',
    } as IServerSurveySettings,
    pages: [
        {
            titleHtml: '',
            pageId: 'page1',
            title: 'На основании всего Вашего опыта взаимодействия с Ростелекомом, насколько Вы готовы рекомендовать своим друзьям и знакомым Интернет от Ростелекома ? ',
            titleVisible: true,
            questions: [
                {
                    questionId: 'V1',
                    type: 'RADIOGROUP',
                    title: 'Поставьте оценку от 0 до 10, 0 – «Точно не будете рекомендовать», 10 – «Точно порекомендуете»',
                    description: 'Test description',
                    values: [
                        '0 - Точно не будете рекомендовать',
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10 - Точно порекомендуете',
                    ],
                    visible: true,
                    colCount: 1,
                    hasOther: false,
                    showLabel: false,
                    condition: '',
                    isRequired: true,
                    maxValue: null,
                } as IServerSurveyPageQuestion,
            ],
            condition: '',
            description:
                'На основании всего Вашего опыта взаимодействия с Ростелекомом, насколько Вы готовы рекомендовать своим друзьям и знакомым Интернет от Ростелекома ? ',
        } as IServerSurveyPage,
        {
            titleHtml: '',
            pageId: 'page2',
            title: '',
            titleVisible: false,
            questions: [
                {
                    questionId: 'First',
                    type: 'CHECKBOX',
                    title: 'С какими проблемами Вы сталкивались при использовании домашнего Интернета от Ростелекома?',
                    values: [
                        'Качество',
                        'Стоимость',
                        'Персонал/Обслуживание',
                        'Проблем не возникало',
                        'Другое',
                    ],
                    visible: true,
                    colCount: 1,
                    hasOther: false,
                    showLabel: false,
                    condition: '',
                    maxValue: null,
                    isRequired: true,
                    description: 'Test description',
                } as IServerSurveyPageQuestion,
                {
                    questionId: 'K1',
                    type: 'CHECKBOX',
                    title: 'Качество',
                    values: [
                        'Неисправность долго устраняли',
                        'Проблемы с предоставленным оборудованием',
                        'Скорость намного ниже заявленной',
                        'Частые сбои в соединениях, частые отключения',
                        'Устаревшая технология и нет возможности подключить интернет по оптике',
                    ],
                    visible: true,
                    colCount: 1,
                    hasOther: false,
                    showLabel: false,
                    condition: "{First} contains ['Качество']",
                    maxValue: null,
                    isRequired: true,
                    description: 'Test description',
                } as IServerSurveyPageQuestion,
                {
                    questionId: 'C1',
                    type: 'CHECKBOX',
                    title: 'Стоимость',
                    values: [
                        'Высокая стоимость. У конкурентов дешевле',
                        'Редко пользуюсь услугой. Не готов платить за полный месяц',
                        'Частое повышение стоимости',
                        'Повышение стоимости без уведомления',
                    ],
                    visible: false,
                    colCount: 1,
                    hasOther: false,
                    showLabel: false,
                    condition: "{First} contains ['Стоимость']",
                    maxValue: null,
                    isRequired: true,
                    description: 'Test description',
                } as IServerSurveyPageQuestion,
                {
                    questionId: 'P1',
                    type: 'CHECKBOX',
                    title: 'Персонал/Обслуживание',
                    values: [
                        'Продавец озвучивал другие цены / скорости / условия',
                        'Недоволен работой офисов',
                        'Недоволен работой колл-центра',
                        'Недоволен работой технического специалиста',
                    ],
                    visible: false,
                    colCount: 1,
                    hasOther: false,
                    showLabel: false,
                    condition: "{First} contains ['Персонал/Обслуживание']",
                    maxValue: null,
                    isRequired: true,
                } as IServerSurveyPageQuestion,
                {
                    questionId: 'DP1',
                    type: 'TEXT',
                    title: 'Другое (пожалуйста, опишите)',
                    visible: false,
                    values: null,
                    colCount: 1,
                    hasOther: false,
                    showLabel: false,
                    condition: "{First} contains ['Другое']",
                    maxValue: null,
                    isRequired: false,
                } as IServerSurveyPageQuestion,
            ],
            condition: "{V1}!='10 - Точно порекомендуете'",
            description: '',
        } as IServerSurveyPage,
        {
            titleHtml: '',
            pageId: 'page3',
            title: '',
            titleVisible: false,
            questions: [
                {
                    questionId: 'MC1',
                    type: 'TEXT',
                    title: 'Расскажите, что Вам нравится в домашнем интернете от Ростелекома и почему Вы готовы порекомендовать его друзьям и знакомым?',
                    visible: true,
                    values: null,
                    colCount: 1,
                    hasOther: false,
                    showLabel: false,
                    condition: '',
                    maxValue: null,
                    isRequired: false,
                } as IServerSurveyPageQuestion,
            ],
            condition: "{V1}>6 OR {V1}='10 - Точно порекомендуете'",
            description: '',
        } as IServerSurveyPage,
    ],
    showPageTitles: true,
    showProgressBar: 'bottom',
    showQuestionNumbers: 'off',
} as IServerSurvey;

export default AnotherTestSurvey;
