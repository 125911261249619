import React from 'react';

import { RadioButton } from '@design-system-rt/rtk-ui-kit';

import { StyledGroup } from './styles';
import { IRadioGroupQuestion } from './types';

const RadioGroupQuestion: React.FC<IRadioGroupQuestion> = (props) => {
    const { questionId, values, onChange, value } = props;

    return (
        <StyledGroup
            onChange={onChange}
            className="content-radio"
            value={value}
        >
            {values.map((value, index) => (
                <RadioButton key={`rb${index}`} name={questionId} value={value}>
                    {value}
                </RadioButton>
            ))}
        </StyledGroup>
    );
};

RadioGroupQuestion.displayName = 'RadioGroupQuestion';

export default RadioGroupQuestion;
