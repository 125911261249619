import { THEMES } from '@design-system-rt/rtk-ui-kit/constants/components';
import styled from 'styled-components';

import { StyledFooterType } from './types';

export const StyledFooter = styled.footer<StyledFooterType>`
    width: 100%;
    background-color: ${({ theme, themeName }) =>
        themeName === THEMES.dark ? theme.greyScale[90] : theme.greyScale[10]};
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: auto;
`;
