import React from 'react';

import { useThemeContext } from '@design-system-rt/rtk-ui-kit';

import { StyledIframe } from './styles';
import { IHtmlQuestion } from './types';

const HtmlQuestion: React.FC<IHtmlQuestion> = (props) => {
    const { html } = props;
    const { themeName } = useThemeContext();

    return (
        <StyledIframe
            src={`data:text/html; charset=utf-8,${html}`}
            themeName={themeName}
        ></StyledIframe>
    );
};

HtmlQuestion.displayName = 'HtmlQuestion';

export default HtmlQuestion;
