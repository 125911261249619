import {
    IQuestionJunction,
    IServerSurvey,
    IServerSurveyPage,
    IServerSurveyPageQuestion,
    IServerSurveySettings,
    IServerSurveyThankPage,
    SurveyPageQuestionType,
} from 'repositories/survey/types';
import { IAppState } from 'store';
import { ConditionRunner } from 'utils/peg/conditions';

import { IAnswer, ISurveyConfig } from './types';

export const surveyDataSelector = ({
    survey,
}: IAppState): IServerSurvey | undefined => survey.data;
export const isSurveyFetchingSelector = ({ survey }: IAppState): boolean =>
    survey.isFetching;
export const surveyErrorSelector = ({
    survey,
}: IAppState): string | undefined => survey.error;
export const surveyAnswersErrorSelector = ({
    survey,
}: IAppState): string | undefined => survey.answersError;
export const surveyAnswersSelector = ({
    survey,
}: IAppState): Array<IAnswer> | undefined => survey.answers;
export const surveyConfigSelector = ({
    survey,
}: IAppState): ISurveyConfig | undefined => survey.config;

export const surveyPagesSelector = ({
    survey,
}: IAppState): Array<IServerSurveyPage> => {
    return survey.data?.pages ?? [];
};

export const surveyCurrentPageSelector = ({
    survey,
}: IAppState): IServerSurveyPage | undefined => {
    const { currentPage, data } = survey;
    return data?.pages.find((page) => page.pageId === currentPage);
};

export const surveyLastPageSelector = (
    state: IAppState
): IServerSurveyPage | undefined => {
    const { data } = state.survey;
    const settings = surveySettingsSelector(state);
    if (settings?.isComplicated) {
        const lastComplicatedPage = getLastPageForComplicatedSurvey(state);
        if (lastComplicatedPage) return lastComplicatedPage;
    }
    return data ? data.pages[data.pages.length - 1] : undefined;
};

export const getLastPageForComplicatedSurvey = (
    state: IAppState
): IServerSurveyPage | undefined => {
    const currentPage = surveyCurrentPageSelector(state);
    if (!currentPage) return undefined;

    const currentPageAnswers = answersForPageSelector(
        state,
        currentPage.pageId
    );

    if (!currentPageAnswers) return getNextPageForComplicatedSurvey(state);

    const currentJunctions = getPageJunctionsWithAnswers(
        currentPage,
        currentPageAnswers
    );

    if (currentJunctions.length === 0) return currentPage;
};

export const surveySettingsSelector = ({
    survey,
}: IAppState): IServerSurveySettings | undefined => {
    const { data } = survey;
    return data?.settings;
};

export const surveyNextPageSelector = (
    state: IAppState
): IServerSurveyPage | undefined => {
    const settings = surveySettingsSelector(state);
    const { currentPage } = state.survey;
    const pages = surveyPagesSelector(state);
    const currentPageId = pages.findIndex(
        (page) => page.pageId === currentPage
    );
    if (settings?.isComplicated) {
        return currentPageId < pages.length - 1
            ? getNextPageForComplicatedSurvey(state)
            : undefined;
    }

    for (let i = currentPageId; i < pages.length; i++) {
        const nextPage = pages[i + 1];
        if (!nextPage) {
            return undefined;
        }
        const { condition, questions } = nextPage;
        if (condition === '' || areConditionsFullfilled(condition, state)) {
            const conditionQuestions = questions.find(
                ({ condition }) =>
                    condition === '' ||
                    areConditionsFullfilled(condition, state)
            );
            if (conditionQuestions) {
                return nextPage;
            }
        }
    }
    return undefined;
};

const getNextPageForComplicatedSurvey = (
    state: IAppState
): IServerSurveyPage | undefined => {
    const currentPage = surveyCurrentPageSelector(state);
    const pages = surveyPagesSelector(state);

    if (!currentPage) {
        return undefined;
    }

    const currentPageAnswers = answersForPageSelector(
        state,
        currentPage.pageId
    );

    if (!currentPageAnswers) return undefined;

    const currentJunctions = getPageJunctionsWithAnswers(
        currentPage,
        currentPageAnswers
    );

    if (!currentJunctions) return undefined;

    const fullfilledJunction = getFullfilledJunctionForPage(
        currentJunctions,
        currentPageAnswers
            .map((answers) => answers.answers.map((answer) => answer.value))
            .flat(2)
    );

    if (!fullfilledJunction) {
        return undefined;
    }

    return pages.find((page) =>
        page.questions.find(
            (question) => question.sequenceId === fullfilledJunction.sequenceId
        )
    );
};

export const answersForPageSelector = (
    state: IAppState,
    pageId: string
): IAnswer[] | undefined => {
    const pages = surveyPagesSelector(state);
    const answers = surveyAnswersSelector(state);
    const page = pages.find((page) => page.pageId === pageId);

    if (!answers || !page) {
        return undefined;
    }

    const pageAnswers = answers.filter((answer) => {
        if (
            page.questions
                .map((question) => question.sequenceId)
                .includes(answer.sequenceId)
        ) {
            return answer;
        }
        return undefined;
    });

    return pageAnswers.map((answer) => {
        const seekedQuestion = page.questions.find(
            (question) => question.sequenceId === answer.sequenceId
        );

        if (seekedQuestion) {
            const { type, hasOther, images } = seekedQuestion;

            switch (type) {
                case SurveyPageQuestionType.RADIOGROUP:
                case SurveyPageQuestionType.CHECKBOX:
                    if (hasOther) {
                        return {
                            ...answer,
                            answers: answer.answers.map((answer) =>
                                answer.type === 'OTHER'
                                    ? { ...answer, value: 'Другое' }
                                    : answer
                            ),
                        };
                    }
                    return answer;
                case SurveyPageQuestionType.IMAGEPICKER:
                    if (images) {
                        const imageIndex = images.findIndex(
                            (image) => answer.answers[0].value === image.url
                        );
                        if (imageIndex !== -1) {
                            return {
                                ...answer,
                                answers: answer.answers.map((answer) => {
                                    return {
                                        ...answer,
                                        value: `${imageIndex + 1}`,
                                    };
                                }),
                            };
                        }
                    }
                    break;
                default:
                    return answer;
            }
        }
        return answer;
    });
};

const getPageJunctionsWithAnswers = (
    page: IServerSurveyPage,
    answers: IAnswer[]
): Array<IQuestionJunction | undefined> =>
    page.questions
        .filter((question) => {
            const { type } = question;
            if (
                type === SurveyPageQuestionType.HTML ||
                type === SurveyPageQuestionType.TEXT
            ) {
                return true;
            }

            return (
                answers.findIndex(
                    (answer) => answer.sequenceId === question.sequenceId
                ) !== -1
            );
        })
        .map(({ junction }) => junction)
        .flat();

const getFullfilledJunctionForPage = (
    junctions: Array<IQuestionJunction | undefined>,
    answers: string[]
) => {
    const emptyJunc: IQuestionJunction[] = [];
    const nonEmptyJunc: IQuestionJunction[] = [];

    junctions.forEach((junction) => {
        if (!junction) return undefined;

        if (junction.answers.length === 0 && answers.length === 0) {
            emptyJunc.push(junction);
        }
        if (
            junction.answers.find((answer: string) => answers.includes(answer))
        ) {
            nonEmptyJunc.push(junction);
        }
    });
    const emptyJuncData = emptyJunc.length > 0 ? emptyJunc[0] : undefined;
    return nonEmptyJunc.length > 0 ? nonEmptyJunc[0] : emptyJuncData;
};

const getPageQuestionsForComplicatedSurvey = (
    state: IAppState,
    page: IServerSurveyPage
): IServerSurveyPageQuestion[] => {
    const pages = surveyPagesSelector(state);
    const navigationStack = surveyNavigationStackSelector(state);

    if (!navigationStack) {
        return page.questions;
    }

    const previousPage = pages.find(
        (page) => page.pageId === navigationStack[navigationStack.length - 1]
    );

    if (!previousPage) {
        return page.questions;
    }

    const previousPageAnswers = answersForPageSelector(
        state,
        previousPage.pageId
    );
    if (!previousPageAnswers) {
        return page.questions;
    }
    const previousPageJunctions = getPageJunctionsWithAnswers(
        previousPage,
        previousPageAnswers
    );

    if (!previousPageJunctions) {
        return page.questions;
    }

    const fullfilledJunction = getFullfilledJunctionForPage(
        previousPageJunctions,
        previousPageAnswers
            .map((answers) => answers.answers.map((answer) => answer.value))
            .flat(2)
    );

    if (!fullfilledJunction) {
        return page.questions;
    }

    return page.questions.filter(
        (question) => question.sequenceId === fullfilledJunction.sequenceId
    );
};

export const surveySettings = ({
    survey,
}: IAppState): IServerSurveySettings | undefined => {
    const { data } = survey;
    return data?.settings;
};

export const surveyNavigationStackSelector = (
    state: IAppState
): string[] | undefined => state.survey.navigationStack;

export const surveyPreviousPageIdSelector = (
    state: IAppState
): string | undefined => state.survey.navigationStack?.slice(-1)[0];

export const pageQuestionsSelector = (
    state: IAppState
): IServerSurveyPageQuestion[] | undefined => {
    const page = surveyCurrentPageSelector(state);
    const settings = surveySettingsSelector(state);
    const questions = page?.questions;

    if (!questions) {
        return undefined;
    }

    if (page && settings?.isComplicated) {
        return getPageQuestionsForComplicatedSurvey(state, page);
    }

    if (questions[0].sequenceId) {
        const filteredQuestions: IServerSurveyPageQuestion[] = [];
        questions.forEach((item) => {
            if (item.showCondition) {
                const answers = surveyAnswerBySequenceIdSelector(state)(
                    item.showCondition.questionId
                );
                const answersValues = answers?.answers.map((ans) => ans.value);

                if (questions[0].type === 'IMAGEPICKER') {
                    const {
                        showCondition: { answerValue },
                    } = item;
                    const { images } = questions[0];

                    if (images && answersValues) {
                        const foundIdx = images?.findIndex((image) =>
                            answersValues?.includes(image.url)
                        );

                        if (answerValue[0] === `${foundIdx + 1}`) {
                            filteredQuestions.push(item);
                        }
                    }
                } else if (
                    answersValues?.find((value) => {
                        // для слайдера связанные вопросы могут привязываться сразу к диапазону,
                        // в этом случае в answersValues могут прийти строки вида "1,2,3"
                        const values =
                            item.showCondition?.answerValue.reduce(
                                (acc, val) => {
                                    acc.push(...val.split(','));
                                    return acc;
                                },
                                [] as string[]
                            ) ?? ([] as string[]);
                        return values.includes(value);
                    })
                ) {
                    filteredQuestions.push(item);
                }
            } else filteredQuestions.push(item);
        });
        return filteredQuestions;
    }
    return questions.filter(
        ({ condition }) =>
            condition === '' || areConditionsFullfilled(condition, state)
    );
};

export const pageQuestionsFinishedSelector = (state: IAppState): boolean => {
    const questions = pageQuestionsSelector(state);

    if (questions) {
        for (const question of questions) {
            if (!question.isRequired) continue;
            const answer = surveyAnswerSelector(state)(question.questionId);
            const nonEmptyAnswers = answer?.answers.find(
                (el) => el.value.trim().length > 0
            );
            if (!nonEmptyAnswers) return false;
        }
    }
    return true;
};

export const surveyQuizEnd = (state: IAppState): boolean => {
    return state.survey.showThankyou;
};

export const surveyAnswerSelector =
    ({ survey }: IAppState) =>
    (questionId?: string): IAnswer | undefined =>
        survey.answers?.find((answer) => answer.questionId === questionId);

export const surveyAnswerBySequenceIdSelector =
    ({ survey }: IAppState) =>
    (questionId?: string): IAnswer | undefined => {
        return survey.answers?.find(
            (answer) => answer.sequenceId === questionId
        );
    };

export const surveyThankYouSelector = ({
    survey,
}: IAppState): IServerSurveyThankPage => {
    const { thankYouPage, data } = survey;
    return data?.thankPage ? data.thankPage : thankYouPage;
};

/**
 * @param {string} condition - Conditions in next question
 * @param {IAppState} state - App state
 * @returns {boolean} - Are conditions fullfilled
 */
const areConditionsFullfilled = (
    condition: string,
    state: IAppState
): boolean => {
    const runner = new ConditionRunner(condition);
    type hashType = {
        [key: string]: string[] | string;
    };
    const hash: hashType = {};

    for (const questionId of runner.getVariables()) {
        const answers = surveyAnswerSelector(state)(questionId);
        const answersValues = answers?.answers.map((ans) => ans.value);
        if (!answersValues) continue;
        hash[questionId] =
            answersValues.length > 1 ? answersValues : answersValues[0];
    }
    return runner.run(hash);
};
