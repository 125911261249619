import React from 'react';

export interface IPageEventProps {
    children: React.ReactNode;
    type?: PageEventType;
}

export enum PageEventType {
    SUCCESS = 'SUCCESS',
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}
