import { push } from 'connected-react-router';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { surveyRepository } from 'repositories/survey/repository';
import { IServerSurvey } from 'repositories/survey/types';

import actions, {
    removePageAnswers,
    setSurveyCurrentPage,
    setSurveyNavigationStack,
} from './actions';
import {
    surveyAnswersSelector,
    surveyConfigSelector,
    surveyPagesSelector,
    surveyNavigationStackSelector,
    surveyCurrentPageSelector,
    surveyThankYouSelector,
    pageQuestionsSelector,
} from './selectors';
import TestSurvey from './testSurvey';
import {
    ActionTypes,
    SurveyAccess,
    SurveyMode,
    IServerThankYou,
} from './types';

function* fetchSurveyWorker(
    action: ReturnType<typeof actions.fetchSurveyRequest>
) {
    try {
        // TODO: temp for dev
        if (action.payload.id !== 'test') {
            let survey: IServerSurvey;
            if (action.payload.mode === SurveyMode.ITEM) {
                survey = yield call(
                    surveyRepository.fetchSurvey,
                    action.payload.id
                );
            } else {
                survey = yield call(
                    surveyRepository.fetchSurveyMetadata,
                    action.payload.id
                );
            }
            yield put(actions.fetchSurveySuccess(survey));
        } else {
            yield put(actions.fetchSurveySuccess(TestSurvey));
        }
    } catch (e) {
        yield put(
            actions.fetchSurveyError(
                e?.message ?? 'Произошла ошибка при запросе опроса.'
            )
        );
    }
}

function* fetchSurveySuccessWorker(
    action: ReturnType<typeof actions.fetchSurveySuccess>
) {
    const pages: ReturnType<typeof surveyPagesSelector> = yield select(
        surveyPagesSelector
    );
    yield put(actions.setSurveyCurrentPage(pages[0].pageId ?? undefined));
}

function* setSurveyAnswerWorker(
    action: ReturnType<typeof actions.setSurveyAnswer>
) {
    const answers: ReturnType<typeof surveyAnswersSelector> = yield select(
        surveyAnswersSelector
    );

    if (action.payload.answers) {
        yield put(
            actions.setSurveyAnswers([
                ...(answers ?? []).filter(
                    (answer) => answer.questionId !== action.payload.questionId
                ),
                {
                    questionId: action.payload.questionId,
                    answers: action.payload.answers,
                    sequenceId: action.payload.sequenceId,
                },
            ])
        );
    }
}

function* setSurveyCurrentPageWorker(
    action: ReturnType<typeof actions.setSurveyCurrentPage>
) {
    if (!action.payload) {
        yield put(actions.setQuizEnd());
        const updatedAnswers: ReturnType<typeof surveyAnswersSelector> =
            yield select(surveyAnswersSelector);
        yield put(actions.fetchAnswersRequest(updatedAnswers));
    }
}

function* setSurveyPreviousPageWorker(
    action: ReturnType<typeof actions.setSurveyPreviousPage>
) {
    const navigationStack: ReturnType<typeof surveyNavigationStackSelector> =
        yield select(surveyNavigationStackSelector);

    const currentPage: ReturnType<typeof surveyCurrentPageSelector> =
        yield select(surveyCurrentPageSelector);
    const questionIds: string[] = [];

    if (currentPage) {
        currentPage.questions.forEach((question) => {
            questionIds.push(question.questionId);
        });
    }

    if (action.payload && questionIds.length > 0) {
        const newStack = navigationStack?.filter((el) => el !== action.payload);
        yield all([
            put(removePageAnswers({ questionIds })),
            put(setSurveyNavigationStack(newStack)),
            put(setSurveyCurrentPage(action.payload)),
        ]);
    }
}

function* setSurveyNextPageWorker(
    action: ReturnType<typeof actions.setSurveyNextPage>
) {
    const currentPage: ReturnType<typeof surveyCurrentPageSelector> =
        yield select(surveyCurrentPageSelector);

    const navigationStack: ReturnType<typeof surveyNavigationStackSelector> =
        yield select(surveyNavigationStackSelector);

    if (currentPage) {
        const newNavStack = navigationStack
            ? [...navigationStack, currentPage?.pageId]
            : [currentPage?.pageId];

        yield all([
            put(setSurveyNavigationStack(newNavStack)),
            put(setSurveyCurrentPage(action.payload)),
        ]);
    }
}

function* fetchAnswersWorker(
    action: ReturnType<typeof actions.fetchAnswersRequest>
) {
    try {
        const config: ReturnType<typeof surveyConfigSelector> = yield select(
            surveyConfigSelector
        );
        const defaultThankYou: ReturnType<typeof surveyThankYouSelector> =
            yield select(surveyThankYouSelector);
        let thankYou: IServerThankYou = {
            utmCampaignLink: '',
            finalPageText: '',
            thankPage: undefined,
        };
        if (
            config?.mode === SurveyMode.ITEM &&
            config.access === SurveyAccess.ANSWER
        ) {
            if (config?.id) {
                thankYou = yield call(
                    surveyRepository.answerSurvey,
                    config?.id,
                    action.payload ?? []
                );
            } else {
                throw new Error('Lost token');
            }
        }

        if (thankYou.thankPage) {
            yield put(actions.fetchAnswersSuccess(thankYou.thankPage));
        } else {
            if (thankYou.finalPageText) {
                defaultThankYou.title = thankYou.finalPageText;
            }
            if (thankYou.utmCampaignLink) {
                defaultThankYou.linkUrl = thankYou.utmCampaignLink;
            }
            yield put(actions.fetchAnswersSuccess(defaultThankYou));
        }
    } catch (e) {
        yield put(
            actions.fetchAnswersError('Произошла ошибка при отправке опроса.')
        );
    }
}

function* removeHiddenAnswersWorker() {
    const currentPage: ReturnType<typeof surveyCurrentPageSelector> =
        yield select(surveyCurrentPageSelector);
    const pageQuestions: ReturnType<typeof pageQuestionsSelector> =
        yield select(pageQuestionsSelector);
    const answers: ReturnType<typeof surveyAnswersSelector> = yield select(
        surveyAnswersSelector
    );

    if (pageQuestions && currentPage && answers) {
        const pageQuestionsIds = pageQuestions?.map(
            (question) => question.sequenceId
        );
        const allQuestionsIds = currentPage?.questions.map(
            (question) => question.sequenceId
        );
        const answerQuestionIdsToRemove = allQuestionsIds?.filter(
            (id) => !pageQuestionsIds?.includes(id)
        );

        const newAnswers = answers.filter((answer) => {
            if (allQuestionsIds.includes(answer.sequenceId)) {
                return !answerQuestionIdsToRemove.includes(answer.sequenceId);
            }
            return answer;
        });
        yield put(actions.setSurveyAnswers(newAnswers));
    }
}

function* surveyFinishWorker() {
    yield put(push('/survey/finish'));
}

function* surveySaga() {
    yield all([
        takeEvery(ActionTypes.ACT_FETCH_SURVEY_REQUEST, fetchSurveyWorker),
        takeEvery(
            ActionTypes.ACT_FETCH_SURVEY_SUCCESS,
            fetchSurveySuccessWorker
        ),
        takeEvery(ActionTypes.ACT_SET_SURVEY_ANSWER, setSurveyAnswerWorker),
        takeEvery(ActionTypes.ACT_FETCH_ANSWERS_REQUEST, fetchAnswersWorker),
        takeEvery(ActionTypes.ACT_FETCH_ANSWERS_SUCCESS, surveyFinishWorker),
        takeEvery(ActionTypes.ACT_FETCH_ANSWERS_ERROR, surveyFinishWorker),
        takeEvery(
            ActionTypes.ACT_SET_SURVEY_CURRENT_PAGE,
            setSurveyCurrentPageWorker
        ),
        takeEvery(
            ActionTypes.ACT_SET_SURVEY_PREVIOUS_PAGE,
            setSurveyPreviousPageWorker
        ),
        takeEvery(
            ActionTypes.ACT_SET_SURVEY_NEXT_PAGE,
            setSurveyNextPageWorker
        ),
        takeEvery(
            ActionTypes.ACT_REMOVE_HIDDEN_ANSWERS,
            removeHiddenAnswersWorker
        ),
    ]);
}

export default surveySaga;
