import { Box } from '@design-system-rt/rtk-ui-kit';
import {
    BREAKPOINTS,
    THEMES,
} from '@design-system-rt/rtk-ui-kit/constants/components';
import { ThemeNamesType } from '@design-system-rt/rtk-ui-kit/types/theme';
import styled from 'styled-components';

export const StyledBox = styled(Box)<{ themeName: ThemeNamesType }>`
    max-width: 816px;
    background-color: ${({ theme, themeName }) =>
        themeName === THEMES.dark
            ? theme.greyScale[90]
            : theme.greyScale.white};
    margin: 0px auto;
    padding: 20px 20px 17px;

    &:first-child {
        margin: 14px auto 0px;
        padding: 20px 20px 17px;
        border-radius: 16px 16px 0px 0px;
    }

    &:last-child {
        border-radius: 0px 0px 16px 16px;

        @media screen and (max-width: ${BREAKPOINTS.tablet_vertical_small}px) {
            border-radius: 0;
            min-height: calc(100vh - 255px);
        }
    }

    &:only-child {
        border-radius: 16px;

        @media screen and (max-width: ${BREAKPOINTS.tablet_vertical_small}px) {
            border-radius: 16px 16px 0px 0px;
            min-height: calc(100vh - 255px);
        }
    }

    .main-header {
        margin-bottom: 20px;
    }

    .title {
        margin-bottom: 8px;
    }

    .description {
        margin-bottom: 26px;
    }

    .content {
        margin-top: 24px;
        display: inline-block;
        width: 100%;
    }

    .content-comment {
        width: 100%;
        min-width: auto;
    }

    .content-radio {
        margin-top: 32px;
        word-break: break-word;

        @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_small}px) {
            span:last-child {
                max-width: 524px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            span:last-child:hover {
                -webkit-line-clamp: 100;
            }
        }
    }

    .content-checkbox {
        margin-top: 32px;
        padding: 0px;
        word-break: break-word;

        @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_small}px) {
            span:last-child {
                max-width: 524px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            span:last-child:hover {
                -webkit-line-clamp: 100;
            }
        }
    }

    .content-radio-stars {
        svg {
            fill: ${({ theme }) => theme.systemColors.warning()};
        }
    }

    .thanks {
        text-align: center;

        @media screen and (max-width: ${BREAKPOINTS.tablet_vertical_small}px) {
            display: flex;
            flex-direction: column;
            margin: 20px 0;
            min-height: calc(100vh - 310px);

            div:last-child {
                margin-top: auto;
                margin-bottom: 0;
            }
        }
    }

    .back-button {
        display: none;
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_small}px) {
        margin: 0px auto;
        padding: 28px 128px;

        .main-header {
            margin-bottom: 20px;
        }

        .title {
            margin-bottom: 16px;
        }

        .description {
            margin-bottom: 32px;
        }

        .content {
            margin-top: 16px;
        }

        &:first-child {
            margin: 32px auto 0px;
            padding: 56px 128px 28px;
            border-radius: 16px 16px 0px 0px;
        }

        &:last-child {
            border-radius: 0px 0px 16px 16px;
        }

        &:only-child {
            border-radius: 16px;
        }

        .back-button {
            display: flex;
        }
    }

    @media screen and (min-width: ${BREAKPOINTS.desktop_small}px) {
        padding-left: 128px;
        padding-right: 128px;
    }
`;
