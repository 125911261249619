import React from 'react';

import {
    IServerSurveyPageQuestion,
    SurveyPageQuestionType,
} from '../../../../repositories/survey/types';
import { AnswerItemType, IAnswer, IAnswerItem } from '../../redux/types';
import CustomCheckboxGroupQuestion from '../CustomCheckboxGroupQuestion/CustomCheckboxGroupQuestion';
import CustomRadioGroupQuestion from '../CustomRadioGroupQuestion/CustomRadioGroupQuestion';
import HtmlQuestion from '../HtmlQuestion/HtmlQuestion';
import ImageGroupQuestion from '../ImageGroupQuestion/ImageGroupQuestion';
import RangeQuestion from '../RangeQuestion/RangeQuestion';
import SmilesQuestion from '../SmilesQuestion/SmilesQuestion';
import StarsQuestion from '../StarsQuestion/StarsQuestion';
import TextQuestion from '../TextQuestion/TextQuestion';

interface IProps {
    question: IServerSurveyPageQuestion;
    getAnswers(questionId: string): IAnswer | undefined;
    setAnswer(
        questionId: string,
        answers: IAnswerItem[],
        sequenceId?: string
    ): void;
}

const QuestionContainer: React.FC<IProps> = (props) => {
    const { question, getAnswers, setAnswer } = props;
    const { questionId, type, maxValue, html, title, sequenceId, images } =
        question;
    const answers = getAnswers(questionId)?.answers;
    const isRecommendQuestion = title
        .toLowerCase()
        .includes('насколько вероятно, что вы порекомендовали бы');
    const sliderStart = () => {
        if (question.descriptionStart) {
            return question.descriptionStart;
        } else {
            return isRecommendQuestion ? 'Точно не порекомендую' : '';
        }
    };
    const sliderEnd = () => {
        if (question.descriptionEnd) {
            return question.descriptionEnd;
        } else {
            return isRecommendQuestion ? 'Точно порекомендую' : '';
        }
    };

    const getSliderMinValue = (): number => {
        return question?.minValue ?? 0;
    };

    const getSliderMaxValue = (): number => {
        return question?.maxValue ?? 10;
    };

    switch (type) {
        case SurveyPageQuestionType.TEXT:
            return (
                <TextQuestion
                    title=""
                    value={answers?.[0].value}
                    onChange={(value) => {
                        setAnswer(
                            questionId,
                            [
                                {
                                    value,
                                    type: AnswerItemType.GENERAL,
                                },
                            ],
                            sequenceId
                        );
                    }}
                />
            );
        case SurveyPageQuestionType.CHECKBOX:
            return (
                <CustomCheckboxGroupQuestion
                    question={question}
                    values={answers}
                    onChange={(values) =>
                        setAnswer(questionId, [...values], sequenceId)
                    }
                />
            );
        case SurveyPageQuestionType.RADIOGROUP:
            return (
                <CustomRadioGroupQuestion
                    question={question}
                    value={answers?.[0]}
                    onChange={(value) =>
                        setAnswer(questionId, [value], sequenceId)
                    }
                />
            );
        case SurveyPageQuestionType.STARS:
            return (
                <StarsQuestion
                    questionId={questionId}
                    value={answers?.[0].value || '0'}
                    onChange={(value) =>
                        setAnswer(
                            questionId,
                            [
                                {
                                    value,
                                    type: AnswerItemType.GENERAL,
                                },
                            ],
                            sequenceId
                        )
                    }
                    count={maxValue ?? 5}
                />
            );
        case SurveyPageQuestionType.RATING:
            return (
                <SmilesQuestion
                    questionId={questionId}
                    value={answers?.[0].value}
                    onChange={(value) =>
                        setAnswer(
                            questionId,
                            [
                                {
                                    value,
                                    type: AnswerItemType.GENERAL,
                                },
                            ],
                            sequenceId
                        )
                    }
                />
            );
        case SurveyPageQuestionType.IMAGEPICKER:
            return (
                <ImageGroupQuestion
                    questionId={questionId}
                    images={images ?? []}
                    value={answers?.[0].value || ''}
                    onChange={(value) =>
                        setAnswer(
                            questionId,
                            [
                                {
                                    value,
                                    type: AnswerItemType.GENERAL,
                                },
                            ],
                            sequenceId
                        )
                    }
                />
            );
        case SurveyPageQuestionType.HTML:
            return (
                <HtmlQuestion
                    html={html ?? ''}
                    onChange={(value) =>
                        setAnswer(
                            questionId,
                            [
                                {
                                    value,
                                    type: AnswerItemType.GENERAL,
                                },
                            ],
                            sequenceId
                        )
                    }
                />
            );
        case SurveyPageQuestionType.SLIDER:
            return (
                <RangeQuestion
                    questionId={questionId}
                    value={answers?.[0].value || `${getSliderMinValue() - 1}`}
                    maxValue={maxValue ?? getSliderMaxValue()}
                    minValue={getSliderMinValue()}
                    descriptionStart={sliderStart()}
                    descriptionEnd={sliderEnd()}
                    showDescription={question.showDescription}
                    showNumbers={question.showNumbers}
                    onChange={(value) =>
                        setAnswer(
                            questionId,
                            [
                                {
                                    value,
                                    type: AnswerItemType.GENERAL,
                                },
                            ],
                            sequenceId
                        )
                    }
                />
            );
        default:
            return null;
    }
};

export default QuestionContainer;
