import RadioGroup from '@design-system-rt/rtk-ui-kit/components/RadioButton/RadioGroup/RadioGroup';
import { BREAKPOINTS } from '@design-system-rt/rtk-ui-kit/constants/components';
import styled from 'styled-components';

export const RadioGroupWrapper = styled(RadioGroup)`
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    .radio-image {
        max-width: 120px;
        border: 5px solid transparent;
    }

    .radio-image-uncheck {
        display: block;
    }

    .radio-image-check {
        display: none;
    }

    label {
        margin-left: 8px;
        margin-right: 8px;

        + label {
            margin-top: 0;
        }

        > span {
            margin: 0;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    input:checked + span {
        .radio-image {
            border: 2px solid #ff4f12;
            box-sizing: border-box;
            border-radius: 8px;
        }

        .radio-image-uncheck {
            display: none;
        }

        .radio-image-check {
            display: block;
        }
    }

    input:hover + span {
        .radio-image-uncheck {
            display: none;
        }

        .radio-image-check {
            display: block;
        }
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_small}px) {
        .radio-image {
            max-width: 148px;
        }

        .radio-image-uncheck,
        .radio-image-check {
            width: 44px;
            height: 40px;
        }

        label {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_large}px) {
        label {
            margin-left: 14px;
            margin-right: 14px;
       

    @media screen and (min-width: ${BREAKPOINTS.tablet_horizontal}px) {
        label {
            margin-left: 20px;
            margin-right: 20px;
        }
    }
`;
