import React, { useEffect, useState } from 'react';

import { AnswerItemType, IAnswerItem } from '../../redux/types';
import RadioGroupQuestion from '../RadioGroupQuestion/RadioGroupQuestion';
import TextQuestion from '../TextQuestion';
import { ICustomRadioGroupQuestion } from './types';

const otherValue = 'Другое';

const CustomRadioGroupQuestion: React.FC<ICustomRadioGroupQuestion> = (
    props
) => {
    const { question, value, onChange } = props;
    let values = question.values ?? [];

    if (question.hasOther && values.indexOf(otherValue) < 0) {
        values = [...values, otherValue];
    }

    const searchRadioValue = (newValue?: IAnswerItem) => {
        if (newValue) {
            if (newValue.type === AnswerItemType.OTHER) {
                return otherValue;
            }

            return newValue.value;
        }

        return undefined;
    };

    const [radioValue, setRadioValue] = useState(searchRadioValue(value));

    useEffect(() => {
        setRadioValue(searchRadioValue(value));
    }, [question, value]);

    return (
        <>
            <RadioGroupQuestion
                questionId={question.questionId}
                values={values}
                value={radioValue}
                onChange={(newValue) => {
                    setRadioValue(newValue);

                    if (newValue === otherValue) {
                        onChange({
                            value: '',
                            type: AnswerItemType.OTHER,
                        });
                    } else {
                        onChange({
                            value: newValue,
                            type: AnswerItemType.GENERAL,
                        });
                    }
                }}
            />
            {question.hasOther && radioValue === otherValue && (
                <TextQuestion
                    title="Свой ответ"
                    value={value?.value}
                    onChange={(newValue) =>
                        onChange({
                            value: newValue,
                            type: AnswerItemType.OTHER,
                        })
                    }
                />
            )}
        </>
    );
};

CustomRadioGroupQuestion.displayName = 'CustomRadioGroupQuestion';

export default CustomRadioGroupQuestion;
