import { BREAKPOINTS } from '@design-system-rt/rtk-ui-kit/constants/components';
import styled, { css } from 'styled-components';

import { IContainer } from './types';

const generateNoGapStyles = ({ noGapMobileS }: IContainer) => {
    if (noGapMobileS) {
        return css`
            padding-left: 0;
            padding-right: 0;
        `;
    }

    return css`
        padding: 0 20px;
    `;
};

export const StyledContainer = styled.div<IContainer>`
    width: 100%;
    min-width: 320px;
    margin: 0 auto;
    box-sizing: border-box;

    ${generateNoGapStyles};

    @media screen and (min-width: ${BREAKPOINTS.mobile_small}px) {
        max-width: ${BREAKPOINTS.tablet_vertical_small - 1}px;
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_small}px) {
        max-width: ${BREAKPOINTS.tablet_vertical_large - 1}px;
        padding: 0 32px;
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_large}px) {
        max-width: ${BREAKPOINTS.tablet_horizontal - 1}px;
        padding: 0 72px;
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_horizontal}px) {
        max-width: ${BREAKPOINTS.desktop_small - 1}px;
        padding: 0 40px;
    }

    @media screen and (min-width: ${BREAKPOINTS.desktop_small}px) {
        max-width: ${BREAKPOINTS.desktop_middle - 1}px;
        padding: 0 0;
    }

    @media screen and (min-width: ${BREAKPOINTS.desktop_middle}px) {
        max-width: 1512px;
        padding: 0 100px;
    }
`;
