import { THEMES } from '@design-system-rt/rtk-ui-kit/constants/components';
import { action } from 'typesafe-actions';

import { ActionTypes } from './types';

export const setViewTheme = (theme: THEMES) => {
    return action(ActionTypes.ACT_SET_VIEW_THEME, theme);
};

export default {
    setViewTheme,
};
