import RadioGroup from '@design-system-rt/rtk-ui-kit/components/RadioButton/RadioGroup/RadioGroup';
import { BREAKPOINTS } from '@design-system-rt/rtk-ui-kit/constants/components';
import styled, { css } from 'styled-components';

const even = (n: number) => !(n % 2);

const centerContent = ({ count }: { count: number }) => {
    if (even(count) || count === 1) {
        return css`
            display: flex;
            justify-content: center;
        `;
    }
    return css``;
};

const generateGrid = ({ count }: { count: number }) => {
    switch (count) {
        case 1:
            return css`
                grid-template-columns: 1fr;
            `;
        case 2:
        case 4:
            return css`
                grid-template-columns: repeat(2, 1fr);
            `;
        case 3:
        case 5:
        case 6:
            return css`
                grid-template-columns: repeat(3, 1fr);
            `;
        default:
            return css``;
    }
};

export const ImageGroupWrapper = styled.div<{ count: number }>`
    ${centerContent};
    @media screen and (max-width: ${BREAKPOINTS.tablet_vertical_small}px) {
        display: flex;
        justify-content: center;
    }
`;

export const ImageGroup = styled(RadioGroup)<{ count: number }>`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;

    @media screen and (min-width: ${BREAKPOINTS.tablet_horizontal}px) {
        ${generateGrid}
        grid-column-gap: 58px;
    }

    .radio-image {
        width: 130px;
        height: 130px;
        border: 2px solid transparent;
        border-radius: 8px;
    }

    .radio-image-uncheck {
        display: block;
    }

    .radio-image-check {
        display: none;
    }

    label {
        margin-bottom: 20px;

        :nth-child(3n) {
            margin-right: 0;
        }

        + label {
            margin-top: 0;
        }

        > span {
            margin: 0;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    input:checked + span {
        .radio-image {
            border: 2px solid #ff4f12;
            box-sizing: border-box;
            border-radius: 8px;
        }

        .radio-image-uncheck {
            display: none;
        }

        .radio-image-check {
            display: block;
        }
    }

    input:hover + span {
        .radio-image-uncheck {
            display: none;
        }

        .radio-image-check {
            display: block;
        }
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_small}px) {
        .radio-image-uncheck,
        .radio-image-check {
            width: 44px;
            height: 40px;
        }
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_horizontal}px) {
        .radio-image {
            width: 148px;
            height: 148px;
        }

        label {
            margin-bottom: 32px;
        }
    }
`;
