import React from 'react';

import { Typography } from '@design-system-rt/rtk-ui-kit';

import { StyledBox } from './styles';
import { IQuestion } from './types';

const Question: React.FC<IQuestion> = (props) => {
    const { themeName, title, description, number, totalNumbers, children } =
        props;

    return (
        <StyledBox
            spacing="xl"
            spacingTop="xxxl"
            spacingBottom="xxxl"
            themeName={themeName}
        >
            {number && (
                <Typography tag="h1" variant="h1" className="main-header">
                    Вопрос {number}{' '}
                    {totalNumbers && (
                        <Typography color="caption" tag="span" variant="h1">
                            из {totalNumbers}
                        </Typography>
                    )}
                </Typography>
            )}

            {title && (
                <Typography tag="h2" variant="h2" className="title">
                    {title}
                </Typography>
            )}

            {description && (
                <Typography
                    color="caption"
                    tag="p"
                    variant="caption"
                    className="description"
                >
                    {description}
                </Typography>
            )}

            {children}
        </StyledBox>
    );
};

Question.displayName = 'Question';

export default Question;
