import React from 'react';

import { StyledRow } from './styles';
import { IRow } from './types';

const Row: React.FC<IRow> = (props) => {
    const { id, className, style, children } = props;

    return (
        <StyledRow id={id} className={className} style={style}>
            {children}
        </StyledRow>
    );
};

Row.displayName = 'Row';

export default Row;
