import { IAnswer, IServerOldThankYou } from 'pages/Survey/redux/types';

import { IServerSurvey } from './types';

export const surveyRepository = {
    async fetchSurvey(token: string): Promise<IServerSurvey> {
        const response = await fetch(`/api/survey/${token}`);
        let data;
        try {
            data = await response.json();
        } finally {
        }

        if (!response.ok) {
            throw Error(data?.message);
        }

        return data;
    },

    async fetchSurveyMetadata(id: string): Promise<IServerSurvey> {
        const response = await fetch(`/api/survey/preview/${id}`);
        let data;
        try {
            data = await response.json();
        } finally {
        }

        if (!response.ok) {
            throw Error(data?.message);
        }

        return data;
    },

    async answerSurvey(
        token: string,
        answers: Array<IAnswer>
    ): Promise<IServerOldThankYou> {
        const mappedAnswers = answers.map((answer) => ({
            questionId: answer.questionId,
            answers: answer.answers.map((item) => item.value),
        }));
        const response = await fetch(`/api/survey/${token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ questions: mappedAnswers }),
        });
        if (!response.ok) {
            throw Error('Ошибка сервера');
        }
        return await response.json();
    },
};
