import React from 'react';
import { useThemeContext } from '@design-system-rt/rtk-ui-kit/hooks/useThemeContext';
import {
    Typography,
    ArrowLeft,
    IconButton,
} from '@design-system-rt/rtk-ui-kit';

import { IAnswer, IAnswerItem } from '../../redux/types';
import {
    IServerSurveyPage,
    IServerSurveyPageQuestion,
} from '../../../../repositories/survey/types';
import Container from '../../../../core/components/Grid/Container';
import Row from '../../../../core/components/Grid/Row';
import Col from '../../../../core/components/Grid/Col';
import Question from '../../../../components/Question';
import QuestionContainer from '../QuestionContainer';
import { StyledButton, StyledButtonsBox } from '../../styles';

interface IProps {
    currentPage: IServerSurveyPage;
    previousPageId?: string;
    nextPage?: IServerSurveyPage;
    finished: boolean;
    questions?: IServerSurveyPageQuestion[];
    businessName?: string;
    currentPageAnswers?: string[];
    lastPage?: IServerSurveyPage;
    setPreviousPage?(pageId: string): void;
    setNextPage?(pageId?: string): void;
    getAnswers(questionId?: string): IAnswer | undefined;
    setAnswer(
        questionId: string,
        answers: IAnswerItem[],
        sequenceId: string
    ): void;
}

const Questions: React.FC<IProps> = ({
    currentPage,
    previousPageId,
    nextPage,
    finished,
    questions,
    businessName,
    currentPageAnswers,
    lastPage,
    setPreviousPage,
    setNextPage,
    getAnswers,
    setAnswer,
}) => {
    const { themeName } = useThemeContext();
    const { title } = currentPage;

    const getNextButtonName = (): string => {
        if (lastPage && lastPage.pageId === currentPage.pageId) {
            return 'Завершить';
        }
        if (
            nextPage ||
            !currentPageAnswers ||
            currentPageAnswers.length === 0
        ) {
            return 'Далее';
        }

        return 'Завершить';
    };

    return (
        <Container noGapMobileS>
            <Row>
                <Col mobileS={12} className="header-container">
                    {previousPageId && setPreviousPage && (
                        <IconButton
                            icon={<ArrowLeft />}
                            className="mobile-back"
                            view="ghost"
                            size="small"
                            onClick={() => setPreviousPage(previousPageId)}
                        />
                    )}
                    <div className={businessName || title ? 'page-header' : ''}>
                        {businessName && (
                            <Typography tag="h1" variant="h1">
                                {businessName}
                            </Typography>
                        )}
                        {title && (
                            <Typography
                                color="caption"
                                tag="body"
                                variant="caption"
                            >
                                {title}
                            </Typography>
                        )}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col mobileS={12} className="survey-questions">
                    {questions &&
                        questions.map((question, idx) => (
                            <Question
                                key={question?.questionId}
                                themeName={themeName}
                                title={question.title}
                                description={question.description ?? ''}
                                number={
                                    questions.length > 1 ? idx + 1 : undefined
                                }
                                totalNumbers={
                                    questions.length > 1
                                        ? questions.length
                                        : undefined
                                }
                            >
                                <QuestionContainer
                                    question={question}
                                    getAnswers={getAnswers}
                                    setAnswer={setAnswer}
                                />

                                {idx === questions.length - 1 && (
                                    <StyledButtonsBox>
                                        {previousPageId && setPreviousPage && (
                                            <StyledButton
                                                className="back-button"
                                                onClick={() =>
                                                    setPreviousPage(
                                                        previousPageId
                                                    )
                                                }
                                                view="ghost"
                                                shape="rounded"
                                            >
                                                Назад
                                            </StyledButton>
                                        )}
                                        {setNextPage && (
                                            <StyledButton
                                                disabled={!finished}
                                                onClick={() =>
                                                    setNextPage(
                                                        nextPage?.pageId
                                                    )
                                                }
                                                shape="rounded"
                                            >
                                                {getNextButtonName()}
                                            </StyledButton>
                                        )}
                                    </StyledButtonsBox>
                                )}
                            </Question>
                        ))}
                </Col>
            </Row>
        </Container>
    );
};

export default Questions;
