import { Box, Button } from '@design-system-rt/rtk-ui-kit';
import {
    BREAKPOINTS,
    THEMES,
} from '@design-system-rt/rtk-ui-kit/constants/components';
import styled from 'styled-components';

export const StyledButtonsBox = styled(Box)`
    display: flex;
    justify-content: center;
    margin: 40px auto 28px;

    @media screen and (max-width: ${BREAKPOINTS.mobile_small}px) {
        flex-direction: column;
    }
`;

export const StyledButton = styled(Button)`
    @media screen and (max-width: ${BREAKPOINTS.mobile_small}px) {
        width: 100%;

        & + & {
            margin-left: 0;
            margin-top: 16px;
        }
    }

    @media screen and (min-width: ${BREAKPOINTS.mobile_small + 1}px) {
        width: 246px;
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_small + 1}px) {
        width: 272px;
        & + & {
            margin-left: 16px;
        }
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_vertical_large + 1}px) {
        width: 168px;
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet_horizontal + 1}px) {
        width: 176px;
    }

    @media screen and (min-width: ${BREAKPOINTS.desktop_small + 1}px) {
        width: 188px;
    }
`;
