import React, { useState } from 'react';

import { RadioButton, Rating, RatingFill } from '@design-system-rt/rtk-ui-kit';

import { StyledRadioGroupWrapper } from './styles';
import { IStartsQuestion } from './types';

const StarsQuestion: React.FC<IStartsQuestion> = (props) => {
    const { questionId, value, count, onChange } = props;
    const [hoverIndx, setHoverIndx] = useState(Number(value) - 1 || 0);

    return (
        <div className="content content-radio content-radio-stars">
            <StyledRadioGroupWrapper onChange={onChange} value={value}>
                {[...Array(count).keys()].map((index) => (
                    <div
                        className="content-radio-stars__container"
                        key={`${index + 1}`}
                        onMouseOver={() => setHoverIndx(index)}
                        onMouseLeave={() =>
                            setHoverIndx(Number(value) - 1 || 0)
                        }
                    >
                        <RadioButton
                            name={`question_${questionId}`}
                            value={`${index + 1}`}
                        >
                            {value &&
                            (index + 1 <= hoverIndx + 1 ||
                                index + 1 <= parseInt(value)) ? (
                                <RatingFill
                                    size={40}
                                    className="radio-image-check radio-image-use"
                                />
                            ) : (
                                <Rating
                                    size={40}
                                    className="radio-image-uncheck radio-image-use"
                                />
                            )}
                        </RadioButton>
                    </div>
                ))}
            </StyledRadioGroupWrapper>
        </div>
    );
};

StarsQuestion.displayName = 'StarsQuestion';

export default StarsQuestion;
