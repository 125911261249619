import React from 'react';

import { Typography } from '@design-system-rt/rtk-ui-kit';
import { useSelector } from 'react-redux';

import PageEvent from 'pages/Event/page';
import { PageEventType } from 'pages/Event/types';
import {
    surveyAnswersErrorSelector,
    surveyThankYouSelector,
} from 'pages/Survey/redux/selectors';
import { StyledButton, StyledButtonsBox } from 'pages/Survey/styles';

const addHttp = (url: string) => {
    if (!/^(f|ht)tps?:\/\//i.test(url)) {
        url = 'http://' + url;
    }
    return url;
};

export default function PageFinish() {
    const error = useSelector(surveyAnswersErrorSelector);
    const thankYouPage = useSelector(surveyThankYouSelector);

    if (error) {
        return <PageEvent type={PageEventType.ERROR}>{error}</PageEvent>;
    }

    if (!thankYouPage) {
        return null;
    }

    const linkButton = addHttp(
        thankYouPage.utmCampaignLink || thankYouPage.linkUrl
    );

    return (
        <PageEvent type={PageEventType.SUCCESS}>
            <Typography variant="h1">{thankYouPage.title}</Typography>
            {thankYouPage.description && (
                <Typography variant="bodyM" style={{ marginTop: '20px' }}>
                    {thankYouPage.description}
                </Typography>
            )}
            {thankYouPage.linkEnabled && (
                <StyledButtonsBox>
                    <StyledButton
                        shape="rounded"
                        onClick={() => window.open(linkButton, '_blank')}
                    >
                        {thankYouPage.linkText}
                    </StyledButton>
                </StyledButtonsBox>
            )}
        </PageEvent>
    );
}
