import React from 'react';

import { useThemeContext } from '@design-system-rt/rtk-ui-kit/hooks/useThemeContext';

import { StyledFooter } from './styles';
import { IFooter } from './types';

const Footer: React.FC<IFooter> = (props) => {
    const { id, className, children } = props;
    const { themeName } = useThemeContext();

    return (
        <StyledFooter id={id} className={className} themeName={themeName}>
            {children}
        </StyledFooter>
    );
};

Footer.displayName = 'Footer';

export default Footer;
