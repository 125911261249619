export interface IImage {
    url: string;
    size?: number;
    name?: string;
}

export interface IServerSurvey {
    settings: IServerSurveySettings;
    pages: Array<IServerSurveyPage>;
    thankPage?: IServerSurveyThankPage | null;
}

export interface IServerSurveySettings {
    businessName: string;
    showPageTitles: boolean;
    showQuestionNumbers: boolean | null;
    showProgressBar: SurveySettingsProgressBarPosition;
    isComplicated?: boolean;
    showQuestionCount?: boolean;
}

type SurveySettingsProgressBarPosition = 'bottom' | 'off';

export interface IServerSurveyPage {
    pageId: string;
    title: string;
    titleHtml: string;
    titleVisible: boolean;
    description: string;
    condition: string;
    questions: Array<IServerSurveyPageQuestion>;
}

export interface IShowCondition {
    questionId: string;
    answerValue: string[];
}

export interface IServerSurveyPageQuestion {
    questionId: string;
    type: SurveyPageQuestionType;
    title: string;
    description?: string;
    isRequired: boolean;
    condition: string;
    showLabel: boolean;
    values: Array<string> | null;
    minValue?: number | null;
    maxValue: number | null;
    colCount: number | null;
    hasOther: boolean;
    html?: string;
    descriptionStart?: string;
    descriptionEnd?: string;
    showDescription?: boolean;
    showNumbers?: boolean;
    sequenceId?: string;
    showCondition?: IShowCondition;
    junction?: Array<IQuestionJunction>;
    images?: IImage[];
}

export interface IQuestionJunction {
    answers: Array<string>;
    sequenceId: string;
}

export enum SurveyPageQuestionType {
    PANEL = 'PANEL', // Deprecated;
    TEXT = 'TEXT',
    RADIOGROUP = 'RADIOGROUP',
    CHECKBOX = 'CHECKBOX',
    DROPDOWN = 'DROPDOWN', // Deprecated;
    COMMENT = 'COMMENT', // Deprecated;
    RATING = 'RATING',
    SLIDER = 'SLIDER', // Deprecated;
    HTML = 'HTML',
    STARS = 'STARS',
    IMAGEPICKER = 'IMAGEPICKER',
}

export interface IServerSurveyThankPage {
    id: number;
    title: string;
    description?: string;
    linkEnabled: boolean;
    linkText: string;
    linkUrl: string;
    utmCampaignLink?: string;
}
