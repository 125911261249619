import React from 'react';

import { StyledContainer } from './styles';
import { IContainer } from './types';

const Container: React.FC<IContainer> = (props) => {
    const { id, className, noGapMobileS, style, children, ...restProps } =
        props;

    return (
        <StyledContainer
            id={id}
            className={className}
            noGapMobileS={noGapMobileS}
            style={style}
            {...restProps}
        >
            {children}
        </StyledContainer>
    );
};

Container.displayName = 'Container';

export default Container;
