import { IServerSurveyThankPage } from './../../../repositories/survey/types';

export enum ActionTypes {
    ACT_FETCH_SURVEY_REQUEST = 'ACT_FETCH_SURVEY_REQUEST',
    ACT_FETCH_SURVEY_SUCCESS = 'ACT_FETCH_SURVEY_SUCCESS',
    ACT_FETCH_SURVEY_ERROR = 'ACT_FETCH_SURVEY_ERROR',
    ACT_SET_SURVEY_ANSWERS = 'ACT_SET_SURVEY_ANSWERS',
    ACT_REMOVE_PAGE_ANSWERS = 'ACT_REMOVE_PAGE_ANSWERS',
    ACT_REMOVE_HIDDEN_ANSWERS = 'ACT_REMOVE_HIDDEN_ANSWERS',
    ACT_QUIZ_END = 'ACT_QUIZ_END',
    ACT_SET_SURVEY_ANSWER = 'ACT_SET_SURVEY_ANSWER',
    ACT_FETCH_ANSWERS_REQUEST = 'ACT_FETCH_ANSWERS_REQUEST',
    ACT_FETCH_ANSWERS_SUCCESS = 'ACT_FETCH_ANSWERS_SUCCESS',
    ACT_FETCH_ANSWERS_ERROR = 'ACT_FETCH_ANSWERS_ERROR',
    ACT_SET_SURVEY_CURRENT_PAGE = 'ACT_SET_SURVEY_CURRENT_PAGE',
    ACT_SET_SURVEY_PREVIOUS_PAGE = 'ACT_SET_SURVEY_PREVIOUS_PAGE',
    ACT_SET_SURVEY_NEXT_PAGE = 'ACT_SET_SURVEY_NEXT_PAGE',
    ACT_SET_SURVEY_NAVIGATION_STACK = 'ACT_SET_SURVEY_NAVIGATION_STACK',
}

export interface ISurveyConfig {
    id: string;
    mode: SurveyMode;
    access: SurveyAccess;
}

export interface IAnswer {
    questionId: string;
    answers: Array<IAnswerItem>;
    sequenceId?: string;
}

export interface IAnswerItem {
    value: string;
    type: AnswerItemType;
}

export interface IServerOldThankYou {
    utmCampaignLink: string;
    finalPageText?: string;
}

export interface IServerThankYou {
    utmCampaignLink?: string;
    finalPageText?: string;
    thankPage?: IServerSurveyThankPage;
}

export enum AnswerItemType {
    GENERAL = 'GENERAL',
    OTHER = 'OTHER',
}

export enum SurveyAccess {
    VIEW = 'VIEW',
    ANSWER = 'ANSWER',
}

export enum SurveyMode {
    META = 'META',
    ITEM = 'ITEM',
}
