import styled from 'styled-components';

const shadowGenerator = (): string => {
    let shadow = '0px 0px 0px 2px #ff4f12,';
    for (let i = -700; i <= 0; i++) {
        i !== 0
            ? (shadow += `${i}px 0 0 -7.5px #ff4f12,`)
            : (shadow += `${i}px 0 0 -7.5px #ff4f12`);
    }
    return shadow;
};

const isNotFF = navigator.userAgent.toLowerCase().indexOf('firefox') === -1;

export const StyledRange = styled.div`
    width: 100%;
    padding-top: 14px;
    position: relative;
    margin-top: 32px;
    font-family: 'Rostelecom Basis';

    &::before {
        content: attr(data-prev);
        left: 0;
    }

    &::after {
        content: attr(data-post);
        right: 0;
    }

    &::before,
    &::after {
        display: block;
        position: absolute;
        top: 0;
        color: rgba(16, 24, 40, 0.5);
        font-size: 10px;
    }

    .ticks {
        display: flex;
        justify-content: space-between;
        margin-top: 3px;
        font-size: 12px;
    }

    .tick {
        border-radius: 100%;
        color: rgba(16, 24, 40, 0.5);
        padding: 0;
        display: block;
        width: 18px;
        font-size: 10px;
        cursor: pointer;
        text-align: center;
        position: relative;

        ${isNotFF
            ? `&::before {
                  content: '';
                  position: absolute;
                  display: block;
                  bottom: 0;
                  right: 0;
                  left: 0;
                  height: 40px;
                }`
            : ''}

        &--active {
            color: #101828;
        }
    }

    .range {
        display: block;
        appearance: none;
        width: 100%;
        margin: 0;
        height: 24px;
        overflow: hidden;
        cursor: pointer;
        background-color: transparent;
        ${isNotFF
            ? `padding-right: 2px;
               padding-left: 2px;`
            : ''}

        &:focus {
            outline: none;
        }
    }

    .range::-webkit-slider-runnable-track {
        width: 100%;
        height: 4px;
        background: rgba(16, 24, 40, 0.05);
    }

    .range::-webkit-slider-thumb {
        position: relative;
        appearance: none;
        height: 20px;
        width: 17px;
        background: #fff;
        border-radius: 4px;
        top: 50%;
        margin-top: -10px;
        box-shadow: ${shadowGenerator()};
        transition: background-color 150ms;
        z-index: 100;
    }

    .range::-moz-range-track,
    .range::-moz-range-progress {
        width: 100%;
        height: 4px;
    }

    .range::-moz-range-track {
        background: rgba(16, 24, 40, 0.05);
    }

    .range::-moz-range-progress {
        width: 100%;
        height: 4px;
        background-color: #ff4f12;
    }

    .range::-moz-range-thumb {
        position: relative;
        appearance: none;
        margin: 0;
        height: 20px;
        width: 16px;
        background: #fff;
        border-radius: 6px;
        border: 2px solid #ff4f12;
        transition: background-color 150ms;
    }

    &.dark {
        .range::-moz-range-track,
        .range::-moz-range-progress {
            background: rgba(255, 255, 255, 0.06);
        }

        .range::-webkit-slider-runnable-track {
            background: rgba(255, 255, 255, 0.06);
        }

        .tick,
        &::before,
        &::after {
            color: rgba(255, 255, 255, 0.4);
        }

        .tick {
            &--active {
                color: rgba(255, 255, 255, 0.9);
            }
        }
    }
`;
