import React from 'react';

import { StyledCheckBoxGroup } from './styles';
import { ICheckboxGroupQuestion } from './types';

const CheckboxGroupQuestion: React.FC<ICheckboxGroupQuestion> = (props) => {
    const { values, defaultChecked, onChange } = props;

    return (
        <StyledCheckBoxGroup
            className="content-checkbox"
            name="checkboxGroup"
            showParentBox={false}
            defaultChecked={defaultChecked}
            checkboxProps={{ shape: 'rounded' }}
            onChange={onChange}
            options={values.map((value) => ({ value, key: value }))}
        />
    );
};

CheckboxGroupQuestion.displayName = 'CheckboxGroupQuestion';

export default CheckboxGroupQuestion;
