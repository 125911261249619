import React, { useState } from 'react';

import { useThemeContext } from '@design-system-rt/rtk-ui-kit';

import { StyledRange } from './styles';
import { IRangeQuestion } from './types';

const RangeQuestion: React.FC<IRangeQuestion> = (props) => {
    const {
        value,
        onChange,
        minValue,
        maxValue,
        descriptionStart,
        descriptionEnd,
        showDescription,
        showNumbers,
    } = props;
    const { themeName } = useThemeContext();
    const [hoverIndx, setHoverIndx] = useState(Number(value) - 1 || 0);
    const [innerValue, setInnerValue] = useState<number>(Number(value));

    const ticks = (): Array<number> => {
        return [
            ...Array.from({ length: maxValue + 2 - minValue }).map(
                (q, i) => i + minValue - 1
            ),
        ];
    };

    const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInnerValue(Number(e.target.value));
        if (Number(e.target.value) === minValue - 1) {
            return onChange('');
        }
        return onChange(e.target.value);
    };

    return (
        <StyledRange
            className={themeName === 'dark' ? 'dark' : ''}
            data-prev={showDescription === false ? '' : descriptionStart}
            data-post={showDescription === false ? '' : descriptionEnd}
        >
            <input
                className="range"
                type="range"
                min={minValue - 1}
                max={maxValue}
                step={1}
                list="ticks"
                onChange={handleRangeChange}
                value={innerValue}
            />
            <div id="ticks" className="ticks">
                {showNumbers !== false &&
                    ticks().map((tick, idx) => {
                        return (
                            <div
                                className={
                                    'tick ' +
                                    (tick <= Number(value) || tick <= hoverIndx
                                        ? 'tick--active'
                                        : '')
                                }
                                key={tick}
                                onMouseOver={() => setHoverIndx(tick)}
                                onMouseLeave={() =>
                                    setHoverIndx(Number(value) || 0)
                                }
                                onMouseDown={() => {
                                    onChange(
                                        tick === minValue - 1
                                            ? ''
                                            : String(tick)
                                    );
                                    setInnerValue(tick);
                                }}
                            >
                                {idx > 0 && tick}
                            </div>
                        );
                    })}
            </div>
        </StyledRange>
    );
};

export default RangeQuestion;
