import {
    BREAKPOINTS,
    THEMES,
} from '@design-system-rt/rtk-ui-kit/constants/components';
import styled from 'styled-components';

import { StyledHeaderType } from './types';

export const StyledHeader = styled.header<StyledHeaderType>`
    width: 100%;
    background-color: ${({ theme, themeName }) =>
        themeName === THEMES.dark
            ? theme.greyScale.black
            : theme.greyScale.white};
    padding: 7px 0;

    @media screen and (max-width: ${BREAKPOINTS.mobile_large}px) {
        padding: 4px 0;
    }
`;
