import { THEMES } from '@design-system-rt/rtk-ui-kit/constants/components';
import styled from 'styled-components';

import { StyledIframeType } from './types';

export const StyledIframe = styled.iframe<StyledIframeType>`
    width: 100%;
    height: 100%;
    border: none;
    background: ${({ theme, themeName }) =>
        themeName === THEMES.dark
            ? 'rgba(255, 255, 255, 0.1)'
            : 'rgba(16, 24, 40, 0.1)'};
`;
