import React from 'react';

import logoMobile from './logo-mobile.svg';
import { IIcon } from './types';

const MobileLogo: React.FC<IIcon> = (props) => (
    <img className={props.className} src={logoMobile} alt="" />
);

MobileLogo.displayName = 'MobileLogo';

export default MobileLogo;
