import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
    setSurveyNextPage,
    setSurveyPreviousPage,
    setSurveyAnswer,
    removeHiddenAnswers,
} from 'pages/Survey/redux/actions';
import {
    surveyCurrentPageSelector,
    surveyNextPageSelector,
    pageQuestionsFinishedSelector,
    surveyPreviousPageIdSelector,
    pageQuestionsSelector,
    surveyAnswerSelector,
    surveySettings,
    answersForPageSelector,
    surveyLastPageSelector,
} from 'pages/Survey/redux/selectors';
import { IAnswerItem } from 'pages/Survey/redux/types';
import { IAppState } from 'store';

import SurveyQuestions from '../SurveyQuestions';

const SurveyPage: React.FC = () => {
    const dispatch = useDispatch();
    const currentPage = useSelector(surveyCurrentPageSelector);
    const currentPageAnswers = useSelector((state: IAppState) => {
        if (currentPage) {
            return answersForPageSelector(state, currentPage.pageId);
        }
    });
    const lastPage = useSelector(surveyLastPageSelector);
    const nextPage = useSelector(surveyNextPageSelector);
    const previousPage = useSelector(surveyPreviousPageIdSelector);
    const pageFinished = useSelector(pageQuestionsFinishedSelector);
    const currentPageQuestions = useSelector(pageQuestionsSelector);
    const getReduxAnswers = useSelector(surveyAnswerSelector);
    const getSurveySettings = useSelector(surveySettings);

    if (!currentPage) {
        return null;
    }

    const scroll = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleAnswerChange = (
        questionId: string,
        answers: IAnswerItem[],
        sequenceId?: string
    ): void => {
        dispatch(setSurveyAnswer({ questionId, answers, sequenceId }));
        dispatch(removeHiddenAnswers());
    };

    const handlePreviousPage = async (pageId: string): Promise<void> => {
        await dispatch(setSurveyPreviousPage(pageId));
        scroll();
    };

    const handleNextPage = async (pageId?: string): Promise<void> => {
        await dispatch(setSurveyNextPage(pageId));
        scroll();
    };

    return (
        <SurveyQuestions
            currentPage={currentPage}
            previousPageId={previousPage}
            nextPage={nextPage}
            finished={pageFinished}
            questions={currentPageQuestions}
            businessName={getSurveySettings?.businessName}
            setPreviousPage={handlePreviousPage}
            setNextPage={handleNextPage}
            getAnswers={getReduxAnswers}
            setAnswer={handleAnswerChange}
            currentPageAnswers={currentPageAnswers
                ?.map((answers) =>
                    answers.answers.map((answer) => answer.value)
                )
                .flat(2)}
            lastPage={lastPage}
        />
    );
};

export default SurveyPage;
