import React from 'react';

import {
    SignIn,
    Sticker,
    Switch,
    Typography,
} from '@design-system-rt/rtk-ui-kit';
import { THEMES } from '@design-system-rt/rtk-ui-kit/constants/components';
import { useThemeContext } from '@design-system-rt/rtk-ui-kit/hooks/useThemeContext';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Logo from 'components/icons/Logo';
import { setViewTheme } from 'redux/view/actions';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Col from '../../core/components/Grid/Col';
import Container from '../../core/components/Grid/Container';
import Row from '../../core/components/Grid/Row';
import age from './age.svg';
import ageDark from './ageDark.svg';
import {
    Column,
    FooterColRight,
    FooterCopyright,
    FooterPhone,
    FooterPolicy,
    StyledLayout,
    StyledLogo,
    StyledMobileLogo,
} from './styles';

const Layout = (props: any) => {
    const { children } = props;
    const { themeName } = useThemeContext();
    const dispatch = useDispatch();

    return (
        <StyledLayout themeName={themeName} className="notranslate">
            <Header>
                <Container>
                    <Row>
                        <Column mobileS={12}>
                            <Link
                                to={{ pathname: 'https://www.rt.ru/' }}
                                target="_blank"
                            >
                                <StyledLogo />
                                <StyledMobileLogo />
                            </Link>
                            <Switch
                                color="primary2"
                                shape="circular"
                                checked={themeName === THEMES.dark}
                                onChange={(value) => {
                                    const newTheme = value
                                        ? THEMES.dark
                                        : THEMES.light;
                                    dispatch(setViewTheme(newTheme));
                                }}
                            />
                        </Column>
                    </Row>
                </Container>
            </Header>

            {children}

            <Footer>
                <Container>
                    <Row>
                        <Col mobileS={12} tabletL={9}>
                            <FooterPhone variant="description" color="caption">
                                Поддержка&nbsp;
                                <a href="tel:8-800-100-0-800">
                                    <Typography
                                        variant="accentS"
                                        color="primary2"
                                        tag="span"
                                    >
                                        8 800 100 0 800
                                    </Typography>
                                </a>
                            </FooterPhone>
                            <FooterPolicy variant="description" color="caption">
                                Продолжая пользование сайтом, вы соглашаетесь на
                                обработку файлов Cookies и других
                                пользовательских данных, в соответствии с{' '}
                                <a href="/" target="_blank">
                                    политикой конфиденциальности
                                </a>
                            </FooterPolicy>
                        </Col>

                        <FooterColRight mobileS={12} tabletL={3}>
                            <Sticker
                                className="sticker"
                                type="info"
                                shape="rounded"
                                disabled
                            >
                                Бета-версия
                            </Sticker>
                            <FooterCopyright variant="caption" color="caption">
                                <span>© 2021 ПАО «Ростелеком»</span>
                                <img
                                    src={
                                        themeName === THEMES.dark
                                            ? ageDark
                                            : age
                                    }
                                    alt="0+"
                                />
                            </FooterCopyright>
                        </FooterColRight>
                    </Row>
                </Container>
            </Footer>
        </StyledLayout>
    );
};

export default Layout;
